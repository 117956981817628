import { ApplicationMetadata, HttpRequestHelpers } from '@aprioritechnologies/core';

import { ACH_API_URL } from '../constants/endpoints';

const METADATA_URL = `${ACH_API_URL}/application-metadata`;

class ApplicationMetadataService {

  static async get(): Promise<ApplicationMetadata> {
    const url = METADATA_URL;
    const headers = HttpRequestHelpers.getDefaultHeaders();

    const { response } = await HttpRequestHelpers.get(url, headers);
    return response;
  }
}

export default ApplicationMetadataService;
