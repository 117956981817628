//
// The following message constants are organized by component hierarchy.
// For example, a message constant that appears in `Header`
// would reside under the 'Header messages' section.
//
// If a message constant should be reused across different components,
// it should reside under the most relevant section.
//
// However, if a message constant is completely dissociated,
// it should reside under the 'Generic' section.
//

// Application messages.
export const NO_APPLICATIONS_AVAILABLE = 'No applications are available';
export const COMPLETE_USER_PROFILE = 'Complete user profile to view applications';

// Notification messages.
export const NO_NOTIFICATIONS_AVAILABLE = 'No notifications available';
export const NOTIFICATION_FILTER_OPTION_ALL = 'All';
export const NOTIFICATION_FILTER_OPTION_DATE_CREATED_NEWEST = 'Newest First';
export const NOTIFICATION_FILTER_OPTION_DATE_CREATED_OLDEST = 'Oldest First';
export const NOTIFICATION_FILTER_OPTION_URGENT = 'Urgent';
export const NOTIFICATION_FILTER_TITLE_PRIORITY = 'Priority';
export const NOTIFICATION_FILTER_TITLE_SORT = 'Sort';
export const NOTIFICATION_FILTER_TITLE_TYPE = 'Type';
export const NOTIFICATION_LIST_COLUMN_INFORMATION = 'Information';
export const NOTIFICATION_LIST_COLUMN_PRIORITY = NOTIFICATION_FILTER_TITLE_PRIORITY;

// Resource messages.
export const NO_RESOURCES_AVAILABLE = 'No resources are available';
