import React from 'react';

import ApplicationList from '../application/application-list';
import NotificationList from '../notification/notifications-list';
import { ResourceList } from '../resource/resource-list';

const Home = () => {
  return (
    <>
      <div key='application-section' className='application-section col-12'>
        <ApplicationList />
      </div>
      <div key='info-section' className='info-section col-12'>
        <div className='col-6'>
          <ResourceList />
        </div>
        <div className='col-6'>
          <NotificationList />
        </div>
      </div>
    </>
  );
};

export default Home;
