import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import {
  Button,
  Dialog,
  Stack,
  useMessage,
  UserEnablements,
  useTheme
} from '@aprioritechnologies/core';

import { asData, useLoadableData } from '../../../hooks/use-loadable-data';
import { User, UserBuilder } from '../../../model/user';
import { useUserService } from '../../../services/user-service';
import { useUserValidation } from '../../user/use-user-validation';
import { UserForm } from '../../user/user-form';

import UserManagementEnablementsForm from './user-management-enablements-form';
import { StyledUserManagementWizard } from './user-management-wizard.styles';

interface UserManagementWizardProps {
  onSave: (user: User) => void;
  closeModal: () => void,
  dialogOpen: boolean,
  user: User
}

enum UserManagementWizardPage {
  Enablements = 0,
  UserProfile = 1
}

export const UserManagementWizard = (props: UserManagementWizardProps) => {

  const { closeModal, onSave, dialogOpen, user } = props;

  const [currentPage, setCurrentPage] = useState(UserManagementWizardPage.Enablements);
  const [updatedUser, setUpdatedUser] = useState(new UserBuilder().copy(user).build());
  const userValidator = useUserValidation(updatedUser);
  const [validUser] = useLoadableData(() => userValidator.isValid(updatedUser), [updatedUser]);

  const [updating, setUpdating] = useState(false);
  const service = useUserService();

  const theme = useTheme();

  const addProfileDetailsMsg = useMessage('user-profile.form.title.add');
  const editProfileDetailsMsg = useMessage('user-profile.form.title.edit');
  const cancelMessage = useMessage('user-profile.button.cancel');
  const previousMessage = useMessage('user-profile.button.previous');
  const nextMessage = useMessage('user-profile.button.next');
  const finishMessage = useMessage('user-profile.button.finish');

  useEffect(() => {
    setUpdatedUser(new UserBuilder().copy(user).build());
  }, [user]);

  const cleanup = (user = new UserBuilder().build()) => {
    setCurrentPage(UserManagementWizardPage.Enablements);
    setUpdatedUser(user);
  };

  const handleCancelClick = () => {
    closeModal();
    cleanup();
  };

  const handleFinishClick = async () => {
    try {
      setUpdating(true);

      const modifiedUser = await service.upsert(user, updatedUser);

      cleanup(modifiedUser);
      onSave(modifiedUser);
    } catch (e) {
      setUpdating(false);
    } finally {
      setUpdating(false);
    }
  };

  const renderEnablementsButtons = () => {
    return (
      <Stack
        className='form-group-buttons'
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-end'
      >
        <Button
          id='previous-button'
          color='error'
          variant='outlined'
          disabled
          style={{ marginRight: theme.spacing(1) }}
        >
          {previousMessage}
        </Button>
        <Button
          id='cancel-button'
          variant='outlined'
          onClick={() => handleCancelClick()}
          style={{ marginRight: theme.spacing(1) }}
        >
          {cancelMessage}
        </Button>
        <Button
          id='next-button'
          variant='contained'
          onClick={() => setCurrentPage(UserManagementWizardPage.UserProfile)}
          disabled={
            updatedUser.canEditEnablements &&
            (!updatedUser.enablements ||
            !updatedUser.enablements.customerAssignedRole)}
        >
          {nextMessage}
        </Button>
      </Stack>
    );
  };

  const renderProfileButtons = () => {
    const isDirty = JSON.stringify(updatedUser) !== JSON.stringify(user);
    return (
      <Stack
        className='form-group-profile-buttons'
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-end'
      >
        <Button
          id='previous-button'
          color='error'
          variant='outlined'
          onClick={() => setCurrentPage(UserManagementWizardPage.Enablements)}
          style={{ marginRight: theme.spacing(1) }}
        >
          {previousMessage}
        </Button>
        <Button
          id='cancel-button'
          variant='outlined'
          onClick={() => handleCancelClick()}
          style={{ marginRight: theme.spacing(1) }}
        >
          {cancelMessage}
        </Button>
        <Button
          id='finish-button'
          variant='contained'
          disabled={!asData(validUser) || updating || !isDirty}
          onClick={() => handleFinishClick()}
        >
          {finishMessage}
        </Button>
      </Stack>
    );
  };

  const onEnablementsChange = (updatedEnablements: UserEnablements) => {
    const next = new UserBuilder().copy(user).enablements(updatedEnablements).build();
    setUpdatedUser(next);
  };

  const renderEnablementsForm = () => {

    return (
      <>
        <UserManagementEnablementsForm
          disabled={!user.canEditEnablements}
          enablements={!!updatedUser.enablements ? updatedUser.enablements : {} as UserEnablements}
          onEnablementsChange={onEnablementsChange}
        />
        {renderEnablementsButtons()}
      </>

    );
  };

  const onProfileChange = (user: User) => {
    setUpdatedUser({ ...user });
  };

  const renderProfileForm = () => {

    return (
      <>
        <UserForm
          isFormDisabled={!user.canEditProfile}
          user={updatedUser}
          onUserChange={onProfileChange}
        />
        {renderProfileButtons()}
      </>
    );
  };

  const renderForm = () => {
    if (currentPage === 0) {
      return renderEnablementsForm();
    }
    return renderProfileForm();
  };

  return (
    <>
      <Helmet>
        <title>{!!updatedUser?.identity ? 'Edit' : 'Add'} User</title>
      </Helmet>
      <Dialog
        className='edit-user-modal'
        isOpen={dialogOpen}
        size='md'
        onClose={handleCancelClick}
        title={updatedUser.identity ? editProfileDetailsMsg : addProfileDetailsMsg}
      >
        <StyledUserManagementWizard>
          {renderForm()}
        </StyledUserManagementWizard>
      </Dialog>
    </>
  );
};
