import { styled } from '@aprioritechnologies/core';

export const StyledApplicationCard = styled('div')(({ theme }) => {
  const border = `1px solid ${theme.palette.grey[300]}`;

  return {
    margin: `${theme.spacing(1)} !important`,
    cursor: 'pointer',
    width: '350px !important',

    '.apriori-card': {
      padding: '1.5rem',
      boxShadow: `0 0 9px 0 ${theme.palette.grey[600]}`,
      '&:hover': {
        boxShadow: `0 0 9px 0 ${theme.palette.common.vividCyan}`
      }
    },

    '.card-header': {
      border: '0px !important',
      height: 'unset',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: `${theme.spacing(1)} !important`
    },

    '.application-icon': {
      display: 'flex',
      flexShrink: 0,
      justifyContent: 'center'
    },

    '.application-name': {
      textTransform: 'none',
      lineHeight: 'initial',
      marginBottom: 0,
      overflow: 'hidden'
    },

    '.light-word': {
      color: theme.palette.primary.light
    },

    '.card-body': {
      paddingTop: 0,
      paddingBottom: 0
    },

    '.application-description': {
      display: '-webkit-box',
      overflow: 'hidden',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      height: '3rem',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2
    },

    '.application-footer': {
      color: theme.palette.grey,
      height: theme.spacing(4),
      textTransform: 'uppercase'
    },

    '.application-list-section-links': {
      display: 'inline-block',
      marginTop: theme.spacing(1)
    },

    '.application-list-section-link': {
      textTransform: 'capitalize',
      marginRight: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRight: border,

      '&:last-child': {
        borderRight: 'none'
      }
    }
  };
});
