import { styled } from '@aprioritechnologies/core';

export const StyledResourceList = styled('div')(({ theme }) => {
  const border = `1px solid ${theme.palette.grey[300]}`;

  return {
    height: 'auto',

    '.resource-list-title': {
      marginBottom: theme.spacing()
    },

    '.resource-list-section': {
      borderRight: border,
      borderBottom: border,
      padding: theme.spacing(2),

      '&:nth-of-type(even)': {
        borderRight: 'none'
      },

      '&:last-child': {
        borderBottom: 'none'
      },

      '&:nth-last-of-type(2)': {
        borderBottom: 'none'
      }
    },

    '.resource-list-section-icon': {
      height: '36px',
      width: '36px'
    },

    '.resource-list-section-description': {
      display: '-webkit-box',
      height: '5rem',
      marginBottom: '0.5rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      webkitBoxOrient: 'vertical',
      webkitLineClamp: 3
    },

    '.resource-list-section-title': {
      fontSize: '1.4rem'
    },

    '.resource-list-section-link': {
      marginRight: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRight: border,

      '&:last-child': {
        borderRight: 'none'
      }
    }
  };
});
