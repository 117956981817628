import { createContext, useContext } from 'react';

import { HttpHelpers, HttpRequestHelpers, User } from '@aprioritechnologies/core';

import {
  CREATE_USER_ACTIVITY_COOKIELESS_SESSION,
  CREATE_USER_ACTIVITY_SSO_EMBED_URL,
  CUSTOMER_USER_ACTIVITY_STATUS,
  GENERATE_USER_ACTIVITY_TOKENS
} from '../constants/endpoints';
import { useLoadableData } from '../hooks/use-loadable-data';

import CloudHomeLocalStorageService from './CloudHomeLocalStorageService';

export interface CreateSsoEmbedUrlResponse {
  url: string;
}

export interface CookielessEmbedSession {
  authenticationToken: string;
  authenticationTokenTtl: number;
  navigationToken: string;
  navigationTokenTtl: number;
  apiToken: string;
  apiTokenTtl: number;
  sessionReferenceToken: string;
  sessionReferenceTokenTtl: number;
}

export interface UserActivityService {
  checkCustomerStatus(): Promise<boolean>;
  createSsoEmbedUrl(): Promise<string>;
  createCookielessEmbedSession(currentUser: User): Promise<CookielessEmbedSession>;
  generateCookielessEmbedTokens(
    sessionReferenceToken: string,
    apiReferenceToken: string,
    navigationReferenceToken: string
  ): Promise<CookielessEmbedSession>;
}

export class HttpUserActivityService implements UserActivityService {
  public constructor(private _http: HttpHelpers) { }

  public async createCookielessEmbedSession(currentUser: User): Promise<CookielessEmbedSession> {

    const existingSessionLookerUserIdentity= CloudHomeLocalStorageService.getLookerSessionUserIdentity();
    let existingSession;
    if (currentUser.identity !== existingSessionLookerUserIdentity) {
      CloudHomeLocalStorageService.removeLookerSessionRefToken();
      CloudHomeLocalStorageService.setLookerSessionUserIdentity(currentUser.identity);
    } else {
      existingSession = CloudHomeLocalStorageService.getLookerSessionRefToken();
    }
    const searchParams = existingSession
      ? `?sessionRefToken=${existingSession}`
      : '';

    const url = CREATE_USER_ACTIVITY_COOKIELESS_SESSION + searchParams;
    const body = {};

    const headers = this._http.getDefaultHeaders();
    const { response } = await this._http.post(url, body, headers);

    // Cache the session.
    CloudHomeLocalStorageService.setLookerSessionRefToken(response.sessionReferenceToken);

    return response;
  }

  public async generateCookielessEmbedTokens(
    sessionReferenceToken: string,
    apiToken: string,
    navigationToken: string
  ) : Promise<CookielessEmbedSession> {

    const url = GENERATE_USER_ACTIVITY_TOKENS;
    const body = JSON.stringify({
      sessionReferenceToken,
      apiToken,
      navigationToken
    });

    const headers = this._http.getDefaultHeaders();
    const { response } = await this._http.put(url, body, headers);

    return response;
  }

  public async createSsoEmbedUrl(): Promise<string> {
    const url = CREATE_USER_ACTIVITY_SSO_EMBED_URL;
    const body = {};

    const headers = this._http.getDefaultHeaders();
    const { response } = await this._http.post(url, body, headers);

    return response.url;
  }

  public async checkCustomerStatus(): Promise<boolean> {
    const url = CUSTOMER_USER_ACTIVITY_STATUS;

    const headers = this._http.getDefaultHeaders();
    const { response } = await this._http.get(url, headers);

    return response;
  }
}

export const createDefaultUserActivityService = (): UserActivityService =>
  new HttpUserActivityService(HttpRequestHelpers);

export const UserActivityServiceContext = createContext(createDefaultUserActivityService());

export const useUserActivityService = () => useContext(UserActivityServiceContext);

export const useUserActivityCookielessEmbedSession = (currentUser: User) => {
  const service = useUserActivityService();
  return useLoadableData(() => service.createCookielessEmbedSession(currentUser), []);
};

export const useUserActivityEmbedUrl = () => {
  const service = useUserActivityService();
  return useLoadableData(() => service.createSsoEmbedUrl(), []);
};

export const useCheckCustomerUserActivityStatus = () => {
  const service = useUserActivityService();
  return useLoadableData(() => service.checkCustomerStatus(), []);
};
