import React, { useState } from 'react';

import { faChartColumn, faHome, faUsers, faUserCheck, faNetworkWired } from '@fortawesome/pro-solid-svg-icons';
import { useHistory, useLocation } from 'react-router';

import {
  EnvironmentHelpers,
  Header,
  Role,
  useMessageFormatter,
  User,
  useWindowNavigation
} from '@aprioritechnologies/core';

// @ts-ignore
import logo from '../../assets/ach-logo.svg';
import { Feature } from '../../constants/feature';
import { ROUTE_USER_ACTIVITY, ROUTE_HOME, ROUTE_USER_MANAGEMENT } from '../../constants/routes';
import { Customer } from '../../model/customer';
import { useFeature } from '../../services/feature/use-feature-flag-service';
import { useCheckCustomerUserActivityStatus } from '../../services/user-activity.service';
import SwitchCustomerModal from '../modal/switch-customer-modal';
import { SwitchDeploymentDialog } from '../modal/switch-deployment-dialog';

import { StyledHeader } from './ach-header.styles';

const packageJson = require('../../../package.json');

interface Props {
  currentUser: User;
  customerList: Customer[];
  selectedCustomerIdentity: Object;
  selectedDeploymentIdentity: string;

  selectCustomer: (customerIdentity: string) => Promise<any>;
  selectDefaultDeployment: () => void;
  selectDeployment: (deploymentIdentity: string) => Promise<any>;
}

export type AchHeaderMessageId =
    'ach-header.home-title' |
    'ach-header.menu-item.switch-customer-title' |
    'ach-header.menu-item.switch-deployment-title' |
    'ach-header.menu-item.user-management' |
    'ach-header.menu-item.view-user-activity' |
    'ach-header.help-menu-item.user-events-guide';

const AchHeader = (props: Props) => {

  const {
    currentUser,
    customerList,
    selectedCustomerIdentity,
    selectedDeploymentIdentity
  } = props;
  const { selectCustomer, selectDefaultDeployment, selectDeployment } = props;

  const [ isSwitchCustomerModalOpen, setIsSwitchCustomerModalOpen ] = useState<boolean>(false);
  const [ isSwitchDeploymentModalOpen, setIsSwitchDeploymentModalOpen ] = useState<boolean>(false);
  const userManagementFeature = useFeature(Feature.UserManagement);
  const userActivityFeature = useFeature(Feature.UserActivity);
  const [ userActivityEnabled ] = useCheckCustomerUserActivityStatus();
  const history = useHistory();
  const formatMessage = useMessageFormatter();
  const location = useLocation();
  const showUserEventGuide = location.pathname.includes(ROUTE_USER_ACTIVITY);

  const { openInNewTab } = useWindowNavigation();

  const isUserAdmin = () => currentUser.roles?.includes(Role.AP_USER_ADMIN);

  const getCustomers = () => {
    return customerList || [];
  };

  const getDeployments = () => {
    const customer = getSelectedCustomer();
    return !customer ? [] : customer.deployments;
  };

  const getSelectedCustomer = () => {
    return getCustomers().find(
      (customer: any) => customer.identity === selectedCustomerIdentity
    );
  };

  const getSelectedDeployment = () => {
    return getDeployments().find(
      (deployment: any) => deployment.identity === selectedDeploymentIdentity
    );
  };

  const navItems = () => {
    const tabs: any[] = [];

    if (window.location.pathname !== ROUTE_HOME) {
      tabs.push({
        link: ROUTE_HOME,
        icon: faHome,
        title: formatMessage('ach-header.home-title')
      });
    }

    return tabs;
  };

  const toggleSwitchCustomerModal = () => {
    setIsSwitchCustomerModalOpen(!isSwitchCustomerModalOpen);
  };

  const userActivityFeatureEnabled = () => {

    return userActivityFeature && isUserAdmin() && userActivityEnabled;

  };

  const handleDeploymentChange = async (deploymentIdentity: string) => {
    await selectDeployment(deploymentIdentity);
    setIsSwitchDeploymentModalOpen(false);
  };

  const renderSwitchCustomerModal = () => {
    const selectedCustomer = getSelectedCustomer();

    if (!selectedCustomer) {
      return null;
    }

    return (
      <SwitchCustomerModal
        key='switch-customer-modal'
        customers={getCustomers()}
        isOpen={isSwitchCustomerModalOpen}
        onSelectionChange={
          (identity: string) => selectCustomer(identity).then(() => selectDefaultDeployment())
        }
        selectedCustomerIdentity={selectedCustomer.identity}
        toggle={toggleSwitchCustomerModal}
      />
    );
  };

  const renderSwitchDeploymentDialog = () => {
    const deployments = getDeployments();
    const selectedDeployment = getSelectedDeployment();

    if (!selectedDeployment) {
      return null;
    }

    return (
      <SwitchDeploymentDialog
        deployments={deployments}
        isOpen={isSwitchDeploymentModalOpen}
        value={selectedDeployment.identity}
        onValueChange={handleDeploymentChange}
        onCancel={setIsSwitchDeploymentModalOpen.bind(null, false)}
      />
    );
  };

  const userMenuItems = () => {
    const dropdownItems: any[] = [];

    // A user can only switch customer if they have access to more than one.
    if (getCustomers().length > 1 && getSelectedCustomer()) {
      dropdownItems.push({
        id: 'user-menu-switch-customer-button',
        icon: faUsers,
        onClick: () => toggleSwitchCustomerModal(),
        title: formatMessage('ach-header.menu-item.switch-customer-title')
      });
    }

    // A user can only switch deployment if they have access to more than one.
    if (getDeployments().length > 1 && getSelectedDeployment()) {
      dropdownItems.push({
        id: 'user-menu-deployment-selection-button',
        icon: faNetworkWired,
        onClick: setIsSwitchDeploymentModalOpen.bind(null, true),
        title: formatMessage('ach-header.menu-item.switch-deployment-title')
      });
    }

    if (userManagementFeature && isUserAdmin()) {
      dropdownItems.push({
        id: 'user-menu-user-management-button',
        icon: faUsers,
        onClick: () => history.push(ROUTE_USER_MANAGEMENT),
        title: formatMessage('ach-header.menu-item.user-management')
      });
    }

    if (userActivityFeatureEnabled()) {
      dropdownItems.push({
        id: 'user-menu-user-activity-button',
        icon: faChartColumn,
        onClick: () => history.push(ROUTE_USER_ACTIVITY),
        title: formatMessage('ach-header.menu-item.view-user-activity')
      });
    }

    return dropdownItems;
  };

  const helpMenuItems = () => {
    const dropdownItems: any[] = [];

    if (showUserEventGuide) {
      dropdownItems.push({
        id: 'help-menu-user-activity-guide-button',
        icon: faUserCheck,
        onClick: () => openInNewTab(
          EnvironmentHelpers.getEnvironmentVariable('REACT_APP_USER_EVENT_GUIDE')
        ),
        title: formatMessage('ach-header.help-menu-item.user-events-guide')
      });
    }

    return dropdownItems;
  };

  return (
    <StyledHeader>
      <Header
        applicationInfo={{
          applicationName: packageJson.title,
          versionNumber: packageJson.version
        }}
        className='ach-header'
        currentUser={currentUser}
        helpMenuItems={helpMenuItems()}
        leftNavItems={navItems()}
        logo={logo}
        userMenuItems={userMenuItems()}
      />
      {renderSwitchCustomerModal()}
      {renderSwitchDeploymentDialog()}
    </StyledHeader>
  );
};

export default AchHeader;
