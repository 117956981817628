import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { EnvironmentHelpers, Loader } from '@aprioritechnologies/core';
import { useCurrentUser } from '@aprioritechnologies/user';

import { Feature } from '../../constants/feature';
import { NO_APPLICATIONS_AVAILABLE } from '../../constants/messages';
import { ROUTE_USER_PROFILE } from '../../constants/routes';
import { Deployment } from '../../model/deployment';
import { useCustomerService } from '../../services/customer.service';
import { useFeature } from '../../services/feature/use-feature-flag-service';

import ApplicationCard from './application-card';
import { StyledApplicationList } from './application-list.styles';

export interface Links {
  docs: string;
  training: string;
  support:string;
}

export interface ApplicationLink {
  cloudReference: string;
  links: Links;
}

const ApplicationList = () => {

  const customerService = useCustomerService();
  const { currentUser: user } = useCurrentUser();
  const rbacRoleManagementFeature = useFeature(Feature.RbacRoleManagement);
  const history = useHistory();

  const [selectedDeployment, setSelectedDeployment] = useState<Deployment>();
  const [loading, setLoading] = useState<Boolean>(true);
  const selectedCustomerIdentity = useSelector((state:any) =>
    state.customer.selectedCustomerIdentity
  );
  const selectedDeploymentIdentity = useSelector((state:any) =>
    state.deployment.selectedDeploymentIdentity
  );

  const hasIncompleteProfile = () => !!user && !user.hasCompleteProfile;

  useEffect(() => {
    if (rbacRoleManagementFeature && hasIncompleteProfile()) {
      history.push(ROUTE_USER_PROFILE);
    }
  }, [rbacRoleManagementFeature]);

  useEffect(() => {
    async function getToken() {
      setLoading(true);
      const token = await customerService.getSelectedDeployment(
        selectedCustomerIdentity || '',
        selectedDeploymentIdentity || '');
      setSelectedDeployment(token);
      setLoading(false);
    }
    getToken();
  },
  [
    selectedCustomerIdentity,
    selectedDeploymentIdentity
  ]);

  const applicationLinks: ApplicationLink[] = [
    {
      cloudReference: 'ci-admin',
      links: {
        docs: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_ADMIN_DOCS'),
        training: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_ADMIN_TRAINING'),
        support: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_ADMIN_SUPPORT')
      } as Links
    },
    {
      cloudReference: 'ci-report',
      links: {
        docs: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_ANALYTICS_DOCS'),
        training: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_ANALYTICS_TRAINING'),
        support: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_ANALYTICS_SUPPORT')
      } as Links
    },
    {
      cloudReference: 'ci-connect',
      links: {
        docs: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_CONNECT_DOCS'),
        training: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_CONNECT_TRAINING'),
        support: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_CONNECT_SUPPORT')
      } as Links
    },
    {
      cloudReference: 'ci-design',
      links: {
        docs: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_DESIGN_DOCS'),
        training: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_DESIGN_TRAINING'),
        support: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_DESIGN_SUPPORT')
      } as Links
    },
    {
      cloudReference: 'ap-pro',
      links: {
        docs: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_PRO_DOCS'),
        training: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_PRO_TRAINING'),
        support: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_PRO_SUPPORT')
      } as Links
    },
    {
      cloudReference: 'cis',
      links: {
        docs: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_WORKSPACE_DOCS'),
        training: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_WORKSPACE_TRAINING'),
        support: EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AP_WORKSPACE_SUPPORT')
      } as Links
    }
  ];

  const findApplicationLinks = (cloudReference: string) => {
    return applicationLinks.find((element) => {
      return element.cloudReference === cloudReference;
    });
  };

  const hasContent = () => {
    return !!selectedDeployment &&
    !!selectedDeployment?.applications &&
    selectedDeployment?.applications.length > 0;
  };

  const renderCards = () => {
    return (
      <div className='application-card-grid'>
        {
          selectedDeployment?.applications.map((app) => (
            <ApplicationCard
              key={`${app.identity}-${app.installationName}`}
              application={app}
              links={findApplicationLinks(app.cloudReference)?.links}
            />
          ))
        }
      </div>
    );
  };

  const renderNoContent = () => <span className='message'>{NO_APPLICATIONS_AVAILABLE}</span>;
  const renderContent = () => hasContent()
    ? renderCards()
    : renderNoContent();

  return (
    <StyledApplicationList className='application-list'>
      {loading
        ? <Loader />
        : renderContent()
      }
    </StyledApplicationList>
  );
};

export default ApplicationList;
