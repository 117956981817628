import React, { useCallback, useEffect, useState } from 'react';

import {
  Assert,
  DeploymentConnectionInfo,
  SubHeader
} from '@aprioritechnologies/core';

import './ach-sub-header.scss';

interface Props {
  selectedCustomerIdentity: any;
  selectedDeploymentIdentity: any;

  listCustomers: any;
  selectDefaultCustomer: any;
  selectDefaultDeployment: any;
}

const AchSubHeader = (props: Props) => {

  const { selectedCustomerIdentity, selectedDeploymentIdentity } = props;
  const { listCustomers, selectDefaultCustomer, selectDefaultDeployment } = props;

  const [ activeCustomer, setActiveCustomer ] = useState<any>();
  const [ activeDeployment, setActiveDeployment ] = useState<any>();
  const [ fetchingActiveCustomer, setFetchingActiveCustomer ] = useState<boolean>(true);
  const [ fetchingActiveDeployment, setFetchingActiveDeployment ] = useState<boolean>(true);

  const getActiveCustomer = useCallback(async () => {
    setFetchingActiveCustomer(true);

    try {
      const { response } = await listCustomers();
      const customers = response.items;

      const customer = customers.find(
        (customer: any) => customer.identity === selectedCustomerIdentity
      );

      if (!customer) {
        selectDefaultCustomer();
        return;
      }

      setActiveCustomer(customer);
    }
    catch (error) {
      console.error(error);
    }

    setFetchingActiveCustomer(false);
  }, [
    listCustomers,
    selectedCustomerIdentity,
    selectDefaultCustomer
  ]);

  const getActiveDeployment = useCallback(() => {
    Assert.notNullOrUndefined(activeCustomer, 'activeCustomer');

    const deployment = activeCustomer!
      .deployments
      .find((deployment: any) => deployment.identity === selectedDeploymentIdentity);

    if (!deployment) {
      selectDefaultDeployment();
      return;
    }

    setActiveDeployment(deployment);
    setFetchingActiveDeployment(false);
  }, [
    activeCustomer,
    selectDefaultDeployment,
    selectedDeploymentIdentity
  ]);

  /**
   * Handle get active customer on mount.
   */
  useEffect(() => {
    getActiveCustomer();
  }, [
    getActiveCustomer
  ]);

  /**
   * Handle get active deployment after customer is fetched.
   */
  useEffect(() => {
    if (!!activeCustomer) {
      getActiveDeployment();
    }
  }, [
    activeCustomer,
    getActiveDeployment
  ]);

  const renderDeploymentConnectionInfo = () => {
    if (fetchingActiveCustomer || fetchingActiveDeployment) {
      return <div className='loading-connection-info'>Loading...</div>;
    }

    return (
      <DeploymentConnectionInfo
        customerName={activeCustomer.name}
        deploymentType={activeDeployment.type}
      />
    );
  };

  return (
    <SubHeader className='ach-sub-header'>
      {renderDeploymentConnectionInfo()}
    </SubHeader>
  );
};

export default AchSubHeader;
