import React from 'react';

import { faFileExclamation, faHome } from '@fortawesome/pro-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { ErrorDisplay, Role, ToastContainer, User } from '@aprioritechnologies/core';

import { APP_NAME } from '../constants/app-metadata';
import { Feature } from '../constants/feature';
import { ROUTE_USER_ACTIVITY, ROUTE_HOME, ROUTE_USER_MANAGEMENT, ROUTE_USER_PROFILE } from '../constants/routes';
import { useFeature } from '../services/feature/use-feature-flag-service';
import { useCheckCustomerUserActivityStatus } from '../services/user-activity.service';

import AchHeader from './header/ach-header.container';
import AchSubHeader from './sub-header/ach-sub-header.container';
import UserActivityView from './views/user-activity/user-activity-view';
import UserManagementView from './views/user-management/user-management-view';
import { UserManagementList } from './views/user-management-list/user-management-list';
import Home from './workspace/Home';
import '@aprioritechnologies/core/dist/index.css';
import '../styles/theme.scss';

interface AppProps {
  currentUser: User
}

/**
 * The main view for this single-page web application.
 */
const App = (props: AppProps)  => {
  const { currentUser } = props;

  const history = useHistory();
  const userManagementFeature = useFeature(Feature.UserManagement);
  const userActivityFeature = useFeature(Feature.UserActivity);
  const [ userActivityEnabled ] = useCheckCustomerUserActivityStatus();

  const isUserAdmin = () => currentUser.roles?.includes(Role.AP_USER_ADMIN);

  const userActivityFeatureEnabled = () => {

    return userActivityFeature && isUserAdmin() && userActivityEnabled;
  };

  const renderUserActivity = () => {
    if (userActivityFeatureEnabled()) {
      return (
        <Route
          exact
          path={ROUTE_USER_ACTIVITY}
          component={() => <UserActivityView currentUser={currentUser}/>}
        />
      );
    }
  };

  const renderUserManagement = () => userManagementFeature && isUserAdmin()
    ? <Route exact path={ROUTE_USER_MANAGEMENT} component={UserManagementList} />
    : <Redirect to={ROUTE_HOME} />;

  // Note here - The AchHeader and AchSubHeader have to take objects
  // becuase the type passed to the App was incorrectly declared
  // but developed against.  So we have to pass the currentUser as if
  // it was an AppProps, but not an actual User.  This needs to be cleaned
  // up by completely removing redux and using TypeScript.
  return (
    <>
      <Helmet>
        <title>{APP_NAME}</title>
      </Helmet>
      <ToastContainer />
      <AchHeader currentUser={{ currentUser }}/>
      <AchSubHeader currentUser={{ currentUser }}/>
      <main>
        <Switch>
          <Route
            exact
            path={ROUTE_HOME}
            component={Home}
          />
          <Route
            exact
            path={ROUTE_USER_PROFILE}
            component={UserManagementView}
          />
          {renderUserActivity()}
          {renderUserManagement()}
          <Route
            component={() => (
              <ErrorDisplay
                buttons={[
                  {
                    icon: faHome,
                    onClick: () => history.push(ROUTE_HOME),
                    text: 'Home'
                  }
                ]}
                icon={faFileExclamation}
                message='It looks like the page you were looking for does not exist.
                  Please contact aPriori Support if you think this is incorrect.'
                title='Page not found.'
              />
            )}
          />
        </Switch>
      </main>
    </>
  );
};

export default App;
