import { styled } from '@aprioritechnologies/core';

export const StyledApplicationList = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    padding: `${theme.spacing(5)} !important`,
    width: '100%',
    maxHeight: '100%',
    height: '100%',
    justifyContent: 'center',
    overflow: 'scroll',

    '.application-card-grid': {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',

      '@media screen and (max-width: 2050px)': {
        gridTemplateColumns: 'repeat(4, 1fr)'
      },

      '@media screen and (max-width: 1650px)': {
        gridTemplateColumns: 'repeat(3, 1fr)'
      }
    }
  };
});
