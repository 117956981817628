import { styled } from '@aprioritechnologies/core';
import { Card } from '@aprioritechnologies/labs';

export const StyledNotificationList = styled(Card)(({ theme }) => {

  return {
    display: 'flex',
    flexDirection: 'column',

    height: '100%',
    paddingTop: theme.spacing(.5),

    '.table-body': {
      flex: '1 0 0',
      alignItems: 'stretch'
    },

    '.notifications-list-title': {
      marginBottom: theme.spacing(.4)
    },

    'label': {
      marginBottom: 0
    },

    '.priority': {
      textAlign: 'center',
      fontSize: '1.5rem'
    }
  };
});
