import { styled } from '@aprioritechnologies/core';

export const StyledUserManagementEnablementsForm = styled('div')(({ theme }) => {

  return {
    '.user-management-wizard-enablements': {
      minHeight: theme.spacing(70),

      '.MuiAlert-root': {
        marginBottom: theme.spacing(2)
      },

      '.MuiAlert-message': {
        whiteSpaceCollapse: 'preserve'
      }
    },

    '#customer-assigned-role-select-field': {
      maxWidth: '70%'
    },

    '.apriori-select > div:nth-of-type(1)': {
      height: '100%'
    },

    '.customer-assigned-role-label': {
      paddingBottom: theme.spacing(.5),
      fontSize: '1.2em'
    },

    '.option-display-name': {
      fontWeight: 600
    },

    '.enablements-required-property': {
      color: theme.palette.grey[600],
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(.5)
    },

    '.enablements-additional-properties, .additional-deployments': {
      marginTop: theme.spacing(2),
      width: 'fit-content'
    },

    '.additional-properties-label, .additional-deployments-label': {
      marginBottom: theme.spacing(1),
      fontSize: '1.2em'
    }
  };
});
