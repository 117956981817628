import { LocalStorageService, LoggingHelpers } from '@aprioritechnologies/core';

const LOOKER_SESSION_REFERENCE_TOKEN = 'LOOKER_SESSION_REFERENCE_TOKEN';
const LOOKER_SESSION_USER_IDENTITY = 'LOOKER_SESSION_USER_IDENTITY';
const SELECTED_CUSTOMER_IDENTITY = 'SELECTED_CUSTOMER_IDENTITY';
const SELECTED_DEPLOYMENT_IDENTITY = 'SELECTED_DEPLOYMENT_IDENTITY';

class CloudHomeLocalStorageService extends LocalStorageService {

  static getLookerSessionRefToken = () => {
    return localStorage.getItem(LOOKER_SESSION_REFERENCE_TOKEN);
  };

  static setLookerSessionRefToken = (sessionRefToken) => {
    LoggingHelpers.logDev('Storing looker session');
    localStorage.setItem(LOOKER_SESSION_REFERENCE_TOKEN, sessionRefToken);
  };

  static removeLookerSessionRefToken = () => {
    localStorage.removeItem(LOOKER_SESSION_REFERENCE_TOKEN);
  };

  static getLookerSessionUserIdentity = () => {
    return localStorage.getItem(LOOKER_SESSION_USER_IDENTITY);
  };

  static setLookerSessionUserIdentity = (userIdentity) => {
    localStorage.setItem(LOOKER_SESSION_USER_IDENTITY, userIdentity);
  };

  static getSelectedCustomerIdentity = () => {
    return localStorage.getItem(SELECTED_CUSTOMER_IDENTITY);
  };

  static setSelectedCustomerIdentity = (deploymentIdentity) => {
    LoggingHelpers.logDev('Storing selected customer identity');
    localStorage.setItem(SELECTED_CUSTOMER_IDENTITY, deploymentIdentity);
  };

  static getSelectedDeploymentIdentity = () => {
    return localStorage.getItem(SELECTED_DEPLOYMENT_IDENTITY);
  };

  static setSelectedDeploymentIdentity = (deploymentIdentity) => {
    LoggingHelpers.logDev('Storing selected deployment identity');
    localStorage.setItem(SELECTED_DEPLOYMENT_IDENTITY, deploymentIdentity);
  };
}

export default CloudHomeLocalStorageService;
