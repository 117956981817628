import { find, first } from 'lodash';

import { DeploymentType } from '@aprioritechnologies/core';

import { DEPLOYMENT_SELECTED } from '../constants/actions';
import CloudHomeLocalStorageService from '../services/CloudHomeLocalStorageService';

export const selectDeployment = identity => async (dispatch) => {
  dispatch({ type: DEPLOYMENT_SELECTED, payload: identity });
  CloudHomeLocalStorageService.setSelectedDeploymentIdentity(identity);
};

export const selectDefaultDeployment = () => async (dispatch, getState) => {
  const { customerList, selectedCustomerIdentity } = getState().customer;

  if (!customerList?.length) {
    return;
  }

  const customer = find(customerList, (c) => c.identity === selectedCustomerIdentity);

  if (!customer) {
    return;
  }

  const { deployments } = customer;

  let deployment = find(deployments, (d) => d.type === DeploymentType.PRODUCTION);
  deployment = deployment || first(deployments);

  if (!deployment) {
    return;
  }

  dispatch(selectDeployment(deployment.identity));
};
