import React, { useMemo } from 'react';

import {
  Alert,
  Checkbox,
  CheckboxState,
  CustomerAssignedRole,
  DeploymentType,
  OptionGroup,
  OptionIdentifier,
  Select,
  TextOverflow,
  Tooltip,
  useMessageFormatter,
  UserEnablements,
  useSafeState,
  useTheme
} from '@aprioritechnologies/core';
import { useCurrentUser } from '@aprioritechnologies/user';

import { Feature } from '../../../constants/feature';
import { asData, dataIsLoaded, dataIsLoading } from '../../../hooks/use-loadable-data';
import { UserProfileId } from '../../../intl/message.type';
import {
  CustomerAssignedRoleView
} from '../../../model/customer-assigned-role-view';
import { useAvailableDeployments } from '../../../services/customer.service';
import { useFeature } from '../../../services/feature/use-feature-flag-service';
import { useEnablements } from '../../../services/user-enablements.service';

import { StyledUserManagementEnablementsForm } from './user-management-enablements-form.styles';

interface UserManagementEnablementsFormProps {
  disabled?: boolean;
  enablements: UserEnablements;
  onEnablementsChange: (newEnablements: UserEnablements) => void;
}

interface AdditionalProperty {
  property: keyof UserEnablements,
  label: UserProfileId,
  requiredRoles: CustomerAssignedRole[],
  disabled?: boolean
}

interface DeploymentProperty extends AdditionalProperty {
  deploymentType: DeploymentType
}

const DEPLOYMENTS: DeploymentProperty[] = [
  {
    property: 'previewEnabled',
    label: 'user-profile.enablements.deployments.preview',
    requiredRoles: [CustomerAssignedRole.APRIORI_DEVELOPER],
    deploymentType: DeploymentType.PREVIEW
  },
  {
    property: 'sandboxEnabled',
    label: 'user-profile.enablements.deployments.sandbox',
    requiredRoles: [],
    deploymentType: DeploymentType.SANDBOX
  }
];

const UserManagementEnablementsForm = (props: UserManagementEnablementsFormProps) => {

  const { disabled, enablements, onEnablementsChange } = props;

  const { currentUser, fetchingCurrentUser } = useCurrentUser();
  const [availableDeployments] = useAvailableDeployments(asData(currentUser)?.customerIdentity);
  const [enablementsList] = useEnablements();

  const formatMessage = useMessageFormatter<UserProfileId>();
  const theme = useTheme();
  const rbacAprioriConnectAdmin = useFeature(Feature.RbacRoleManagementAprioriConnectAdmin);
  const rbacAprioriExportAdmin = useFeature(Feature.RbacRoleManagementAprioriExportAdmin);

  const ADDITIONAL_PROPERTIES: AdditionalProperty[] = [
    {
      property: 'connectAdminEnabled',
      label: 'user-profile.enablements.additional-properties.connect.admin',
      requiredRoles: [],
      disabled: !rbacAprioriConnectAdmin
    },
    {
      property: 'userAdminEnabled',
      label: 'user-profile.enablements.additional-properties.user.admin',
      requiredRoles: []
    },
    {
      property: 'highMemEnabled',
      label: 'user-profile.enablements.additional-properties.high.memory',
      requiredRoles: [CustomerAssignedRole.APRIORI_EXPERT, CustomerAssignedRole.APRIORI_DEVELOPER]
    },
    {
      property: 'exportAdminEnabled',
      label: 'user-profile.enablements.additional-properties.export.admin',
      requiredRoles: [
        CustomerAssignedRole.APRIORI_EXPERT,
        CustomerAssignedRole.APRIORI_DEVELOPER,
        CustomerAssignedRole.APRIORI_DESIGNER
      ],
      disabled: !rbacAprioriExportAdmin
    }
  ];

  const [selectedRole, setSelectedRole] = useSafeState<OptionIdentifier | undefined>(
    !!enablements ? enablements.customerAssignedRole : undefined
  );

  const loading = dataIsLoading(enablementsList)
    || dataIsLoading(availableDeployments)
    || fetchingCurrentUser;

  const customerAssignedRoles = useMemo(() => {
    if (!dataIsLoaded(enablementsList)) {
      return [];
    }
    return enablementsList.customerAssignedRoles
      .filter(role => role.name !== 'APRIORI_USER')
      .sort((a: CustomerAssignedRoleView, b: CustomerAssignedRoleView) =>
        a.name > b.name ? 1 : -1
      );
  }, [enablementsList]);

  const getRoleOptions = (): OptionGroup[] => {
    const options: any = [];

    customerAssignedRoles.forEach(role => (
      options.push({
        displayName: role.name,
        identifier: role.name,
        data: role.applications
      })
    ));

    return [{
      options
    }];
  };

  const renderOption = (option: any) => {
    return (
      <div className='customer-assigned-role-option'>
        <div className='option-display-name'>{option.displayName.replace('_', ' ')}</div>
        <TextOverflow
          className='option-applications-list'
          tooltipContent={option.displayName}
          maxLines={3}
        >
          {option?.data
            .filter((application: any) => !application.name.includes('Service'))
            .map((application: any) => application.name).join(', ')
          }
        </TextOverflow>
      </div>
    );
  };

  const renderValue = (option: any) => {

    return (
      <>
        {option.displayName.replace('_', ' ')}
      </>
    );
  };

  const handleRoleChange = (name: any) => {
    const selectedRole = customerAssignedRoles?.find(
      (role: CustomerAssignedRoleView) => role.name === name
    );

    if (!!selectedRole) {
      const updatedEnablements = {
        ...enablements,
        customerAssignedRole: selectedRole.name
      };

      if (name !== CustomerAssignedRole.APRIORI_DEVELOPER
        && name !== CustomerAssignedRole.APRIORI_EXPERT) {
        updatedEnablements.highMemEnabled = false;
      }
      if (name !== CustomerAssignedRole.APRIORI_EXPERT) {
        updatedEnablements.exportAdminEnabled = false;
      }
      if (name !== CustomerAssignedRole.APRIORI_DEVELOPER) {
        updatedEnablements.previewEnabled = false;
      }

      onEnablementsChange(updatedEnablements as UserEnablements);
      setSelectedRole(name);
    }
  };

  const handleCheckboxChange = (property: keyof UserEnablements) => {
    const updatedEnablements = {
      ...enablements,
      [property]: !enablements[property]
    };

    onEnablementsChange(updatedEnablements as UserEnablements);
  };

  const isCheckboxDisabled = (property: AdditionalProperty) => {
    const { disabled, requiredRoles } = property;

    if (disabled) return true;

    if (requiredRoles.length === 0) {
      return false;
    }
    return !requiredRoles.includes(selectedRole as CustomerAssignedRole);
  };

  const isDeploymentCheckboxDisabled = (deployment: DeploymentProperty) => {

    return !dataIsLoaded(availableDeployments) ||
      !availableDeployments.some((d) => deployment.deploymentType === d.deploymentType) ||
      isCheckboxDisabled(deployment);
  };

  const getTooltip = (property: AdditionalProperty) => {
    const { label, disabled, requiredRoles } = property;

    if (disabled) {
      return formatMessage('user-profile.enablements.additional-properties.disabled.coming-soon');
    }
    if (isCheckboxDisabled(property)) {
      return formatMessage(
        'user-profile.enablements.additional-properties.disabled.tooltip',
        {
          property: <b key='b1'>{formatMessage(label).toString()}</b>,
          customerAssignedRoles: <b key='b2'>{requiredRoles.join(', ').toString()}</b>
        });
    }
    return undefined;
  };

  const getDeploymentTooltip = (deployment: DeploymentProperty) => {
    if (dataIsLoaded(availableDeployments) &&
      availableDeployments.filter(
        (dep: any) => deployment.deploymentType === dep.deploymentType).length === 0) {

      return formatMessage(
        'user-profile.enablements.additional-deployment.disabled.tooltip',
        {
          property: <strong key='b1'>{formatMessage(deployment.label).toString()}</strong>
        }
      );
    }

    if (isCheckboxDisabled(deployment)) {
      return formatMessage(
        'user-profile.enablements.additional-properties.disabled.tooltip',
        {
          property: <strong key='b1'>{formatMessage(deployment.label).toString()}</strong>,
          customerAssignedRoles: (
            <strong key='b2'>
              {deployment.requiredRoles.join(', ').toString()}
            </strong>
          )
        }
      );
    }

    return undefined;
  };

  const renderEnablementsForm = () => {

    return (
      <StyledUserManagementEnablementsForm>
        <div className='user-management-wizard-enablements'>
          {
            disabled && (
              <Alert>
                {formatMessage('user-profile.enablements.disabled')}
              </Alert>
            )
          }
          <div style={{ marginBottom: theme.spacing(4) }}>
            <div className='customer-assigned-role-label'>
              {formatMessage('user-profile.role.label')}
            </div>
            <Select
              disabled={loading || disabled}
              notNull
              placeholder={formatMessage('user-profile.select.placeholder')}
              id='customer-assigned-role-select-field'
              onSelect={handleRoleChange}
              options={getRoleOptions()}
              value={selectedRole}
              renderOption={renderOption}
              renderValue={renderValue}
            />
            <div className='enablements-required-property'>
              {formatMessage('user-profile.form.required')}
            </div>
            <div className='enablements-additional-properties'>
              <div className='additional-properties-label'>
                {formatMessage('user-profile.enablements.additional-properties.label')}
              </div>
              {ADDITIONAL_PROPERTIES.map((additionalProperty) => (
                <Tooltip
                  placement='right-start'
                  key={additionalProperty.property}
                  title={getTooltip(
                    additionalProperty
                  )}
                >
                  <div>
                    <Checkbox
                      className='additional-enablements-checkbox'
                      label={formatMessage(additionalProperty.label)}
                      isDisabled={
                        isCheckboxDisabled(additionalProperty)
                        || loading
                        || disabled
                      }
                      onClick={() => handleCheckboxChange(additionalProperty.property)}
                      state={enablements[additionalProperty.property]
                        ? CheckboxState.CHECKED
                        : CheckboxState.NOT_CHECKED
                      }
                      style={{ marginBottom: theme.spacing(1) }}
                      value={additionalProperty.property}
                    />
                  </div>
                </Tooltip>
              ))}
            </div>
            <div className='additional-deployments'>
              <div className='additional-deployments-label'>
                {formatMessage('user-profile.enablements.deployments.label')}
              </div>
              {DEPLOYMENTS.map((deployment) => (
                <Tooltip
                  placement='right-start'
                  key={deployment.property}
                  title={getDeploymentTooltip(deployment)}
                >
                  <div>
                    <Checkbox
                      className='additional-deployments-checkbox'
                      label={formatMessage(deployment.label)}
                      isDisabled={isDeploymentCheckboxDisabled(deployment) || disabled}
                      onClick={() => handleCheckboxChange(deployment.property)}
                      state={enablements[deployment.property]
                        ? CheckboxState.CHECKED
                        : CheckboxState.NOT_CHECKED
                      }
                      style={{ marginBottom: theme.spacing(1) }}
                      value={deployment.property}
                    />
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        </div>
      </StyledUserManagementEnablementsForm>
    );
  };

  return renderEnablementsForm();
};

export default UserManagementEnablementsForm;
