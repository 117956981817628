/**
 * The following polyfills MUST be specified at the top of this file
 * before anything else and MUST be specified in this order.
 *
 * These polyfills provide support for IE11 and can be found here:
 * - https://www.npmjs.com/package/react-app-polyfill
 * - https://www.npmjs.com/package/babel-polyfill
 */
import 'react-app-polyfill/ie11';
import 'babel-polyfill';

import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import {
  ApplicationMetadataInitializer,
  ApplicationMetadataProvider,
  Auth0Provider,
  EnvironmentHelpers,
  legacyTheme,
  ThemeProvider
} from '@aprioritechnologies/core';
import { AuthenticationInitializer, AuthorizationInitializer } from '@aprioritechnologies/user';

import App from './components/app';
import configureStore from './configureStore';
import GainsightProvider from './context/gainsight.container';
import history from './history';
import GainsightInitializer from './initializers/gainsight-initializer';
import AchEn from './intl/en';
import AchApi from './services/ach.api';
import * as serviceWorker from './serviceWorker';

const initialState = {};

// Create and export the store to allow non react components
// to dispatch actions.
export const store = configureStore(initialState, history);

EnvironmentHelpers.checkEnvironment(
  'REACT_APP_AUTH0_CALLBACK_URL',
  'REACT_APP_AUTH0_CLIENT_ID',
  'REACT_APP_AUTH0_DOMAIN',
  'REACT_APP_AUTH0_LOGOUT_URL',
  'REACT_APP_GAINSIGHT_ENABLED',
  'REACT_APP_SUPPORTING_API_URL'
);

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

render(
  <Provider store={store}>
    <BrowserRouter>

      <ConnectedRouter history={history}>
        <HelmetProvider>
          <ThemeProvider theme={legacyTheme}>
            <Auth0Provider
              client_id={EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AUTH0_CLIENT_ID')}
              domain={EnvironmentHelpers.getEnvironmentVariable('REACT_APP_AUTH0_DOMAIN')}
              onRedirectCallback={onRedirectCallback}
              useRefreshTokens
              redirect_uri={window.location.origin}
              response_type='token id_token'
              scope='openid profile email'
            >
              <ApplicationMetadataProvider onGetApplicationMetadata={AchApi.ApplicationMetadata.get}>
                <AuthenticationInitializer>
                  { () => (
                    <IntlProvider locale='en' messages={AchEn}>
                      <AuthorizationInitializer>
                        { (currentUser) => (
                          <ApplicationMetadataInitializer>
                            <GainsightProvider
                              currentUser={currentUser}
                              enabled={EnvironmentHelpers.getEnvironmentVariable(
                                'REACT_APP_GAINSIGHT_ENABLED'
                              )}
                              gainsightTagKey={EnvironmentHelpers.getEnvironmentVariable(
                                'REACT_APP_GAINSIGHT_TAG_KEY',
                                true
                              )}
                            >
                              <GainsightInitializer currentUser={currentUser}>
                                <App currentUser={currentUser}/>
                              </GainsightInitializer>
                            </GainsightProvider>
                          </ApplicationMetadataInitializer>
                        )}
                      </AuthorizationInitializer>
                    </IntlProvider>
                  )}
                </AuthenticationInitializer>
              </ApplicationMetadataProvider>
            </Auth0Provider>
          </ThemeProvider>
        </HelmetProvider>
      </ConnectedRouter>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
