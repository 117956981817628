import { find, first } from 'lodash';

import { HttpRequestHelpers } from '@aprioritechnologies/core';

import {
  CUSTOMER_SELECTED,
  LIST_CUSTOMERS_FAILED,
  LIST_CUSTOMERS_PENDING,
  LIST_CUSTOMERS_SUCCEEDED
} from '../constants/actions';
import { CUSTOMERS_URL } from '../constants/endpoints';
import CloudHomeLocalStorageService from '../services/CloudHomeLocalStorageService';

export const selectCustomer = identity => async (dispatch) => {
  dispatch({ type: CUSTOMER_SELECTED, payload: identity });
  CloudHomeLocalStorageService.setSelectedCustomerIdentity(identity);
};

export const selectDefaultCustomer = (user) => async (dispatch, getState) => {
  const state = getState();
  const { customer: { customerList } } = state;
  const { currentUser: { customerIdentity } } = user;
  const customer = find(customerList, (c) => c.identity === customerIdentity) || first(customerList);

  if (customer) {
    dispatch({ type: CUSTOMER_SELECTED, payload: customer.identity });
    CloudHomeLocalStorageService.setSelectedCustomerIdentity(customer.identity);
  }
};

export const listCustomers = () => async (dispatch) => {

  dispatch({ type: LIST_CUSTOMERS_PENDING });

  const headers = HttpRequestHelpers.getDefaultHeaders();
  const url = `${CUSTOMERS_URL}?pageNumber=1&pageSize=1000000`;

  return HttpRequestHelpers.get(url, headers)
    .then(response => {
      dispatch({ type: LIST_CUSTOMERS_SUCCEEDED, payload: response.response });
      return response;
    })
    .catch(() => {
      dispatch({ type: LIST_CUSTOMERS_FAILED });
    });
};
