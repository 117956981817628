import {
  LIST_NOTIFICATIONS_FAILED,
  LIST_NOTIFICATIONS_PENDING,
  LIST_NOTIFICATIONS_SUCCEEDED
} from '../constants/actions';

const initialState = {
  notificationList: [],
  listNotificationsRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  }
};

// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
  switch (action.type) {

    case LIST_NOTIFICATIONS_FAILED: {
      const listNotificationsRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        listNotificationsRequestStatus
      };
    }

    case LIST_NOTIFICATIONS_PENDING: {
      const listNotificationsRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        listNotificationsRequestStatus
      };
    }

    case LIST_NOTIFICATIONS_SUCCEEDED: {
      const listNotificationsRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        notificationList: action.payload.items,
        listNotificationsRequestStatus
      };
    }

    default: {
      return state;
    }

  }
}
