import { createContext, useContext } from 'react';

import { HttpHelpers, HttpRequestHelpers, PagedResults } from '@aprioritechnologies/core';
import { DataRequest, DataRequestBuilder, DataSource, SearchCriterionBuilder } from '@aprioritechnologies/data-source-models';
import { CurrentUserService, HttpCurrentUserService } from '@aprioritechnologies/user';

import { CUSTOMER_USERS_URL, CUSTOMER_USER_URL } from '../constants/endpoints';
import { DeepPartial, diff } from '../helpers/diff';
import { User } from '../model/user';

export interface UserService extends DataSource<User> {
  create(user: User): Promise<User>;
  update(userIdentity: string, user: Partial<User>): Promise<User>;
  upsert(user: User, next: User): Promise<User>;
  delete(userIdentity: string): Promise<void>;
}

export class HtmlUserService implements UserService {
  public constructor(private _http: HttpHelpers, private _current: CurrentUserService) { }

  public async url(userIdentity?: string) {
    const { customerIdentity } = await this._current.get();
    return userIdentity == null
      ? CUSTOMER_USERS_URL(customerIdentity)
      : CUSTOMER_USER_URL(customerIdentity, userIdentity);
  }

  public async list(req: DataRequest): Promise<PagedResults<User>> {
    const baseUrl = await this.url();

    let $req = new DataRequestBuilder().copy(req);

    if (req.search) {
      const filter = new SearchCriterionBuilder().subject('username').contains(req.search).build();
      $req = $req.filter(filter).search('');
    }

    const url = $req.buildUrl(baseUrl);

    const headers = this._http.getDefaultHeaders();
    const { response } = await this._http.get(url, headers);
    return response;
  }

  public async create(user: User): Promise<User> {
    const url = await this.url();
    const body = JSON.stringify({ user });

    const headers = this._http.getDefaultHeaders();
    const { response } = await this._http.post(url, body, headers);
    return response;
  }

  public async update(userIdentity: string, user: DeepPartial<User>): Promise<User> {
    const url = await this.url(userIdentity);
    const body = JSON.stringify({ user });

    const headers = this._http.getDefaultHeaders();
    const { response } = await this._http.patch(url, body, headers);
    return response;
  }

  public async upsert(user: User, next: User) {
    if (!user.identity) {
      return this.create(next);
    }

    const payload: DeepPartial<User> = diff(next, user);
    return this.update(user.identity, payload);
  }

  public async delete(userIdentity: string): Promise<void> {
    const url = await this.url(userIdentity);
    const headers = this._http.getDefaultHeaders();
    await this._http.$delete(url, headers);
  }
}

export const createDefaultUserService = (): UserService =>
  new HtmlUserService(HttpRequestHelpers, new HttpCurrentUserService(HttpRequestHelpers));

export const UserServiceContext = createContext(createDefaultUserService());

export const useUserService = () => useContext(UserServiceContext);

// Alias to useUserService
export const useUserDataSource: () => DataSource<User> = useUserService;
