/* eslint-disable no-redeclare */
import { get, set } from 'lodash';

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export function diff<T extends object>(
  whatYouWant: T,
  whatTheOriginalIs: T
): DeepPartial<T>;

export function diff<T extends object>(
  whatYouWant: null | undefined,
  whatTheOriginalIs: T
): null | undefined;

export function diff<T extends object>(
  whatYouWant: T,
  whatTheOriginalIs: null | undefined
): DeepPartial<T>;

export function diff(
  whatYouWant: null | undefined,
  whatTheOriginalIs: null | undefined
): null | undefined;

export function diff<T extends object>(
  whatYouWant: T | null | undefined,
  whatTheOriginalIs: T | null | undefined
): DeepPartial<T> | null | undefined {

  if (whatYouWant == null) {
    return whatYouWant;
  }

  const difference: any = {};
  const keys = Object.keys(whatYouWant);

  keys.forEach((key) => {
    const nextValue = get(whatYouWant, key);
    const originalValue = get(whatTheOriginalIs, key);

    if (nextValue === originalValue) {
      return;
    }

    if (typeof nextValue === 'object' && typeof originalValue === 'object') {
      if (JSON.stringify(nextValue) === JSON.stringify(originalValue)) {
        delete difference[key];
      }
      else {
        set(difference, key, diff(nextValue, originalValue));
      }

      return;
    }

    set(difference, key, nextValue);
  });

  return difference;
};
