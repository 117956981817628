import {
  CUSTOMER_SELECTED,
  LIST_CUSTOMERS_FAILED,
  LIST_CUSTOMERS_PENDING,
  LIST_CUSTOMERS_SUCCEEDED
} from '../constants/actions';

const initialState = {
  customerList: [],
  listCustomerRequestStatus: {
    failed: false,
    pending: false,
    succeeded: false
  },
  selectedCustomerIdentity: null
};

// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
  switch (action.type) {

    case CUSTOMER_SELECTED: {
      return {
        ...state,
        selectedCustomerIdentity: action.payload
      };
    }

    case LIST_CUSTOMERS_FAILED: {
      const listCustomerRequestStatus = {
        failed: true,
        pending: false,
        succeeded: false
      };

      return {
        ...state,
        listCustomerRequestStatus
      };
    }
    case LIST_CUSTOMERS_PENDING: {
      const listCustomerRequestStatus = {
        failed: false,
        pending: true,
        succeeded: false
      };

      return {
        ...state,
        listCustomerRequestStatus
      };
    }

    case LIST_CUSTOMERS_SUCCEEDED: {
      const listCustomerRequestStatus = {
        failed: false,
        pending: false,
        succeeded: true
      };

      return {
        ...state,
        customerList: action.payload.items,
        listCustomerRequestStatus
      };
    }

    default: {
      return state;
    }

  }
}
