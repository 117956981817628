import React, { useEffect, useState } from 'react';

import { isEqual } from 'lodash';
import { Helmet } from 'react-helmet-async';

import {
  AdditionalUserEnablements,
  Alert,
  Button,
  Dialog,
  Label,
  UserProfile,
  useAuth0,
  useMessage,
  useSafeState,
  useTheme
} from '@aprioritechnologies/core';
import { useCurrentUser, useCurrentUserService } from '@aprioritechnologies/user';

import { APP_NAME } from '../../../constants/app-metadata';
import { Feature } from '../../../constants/feature';
import { diff } from '../../../helpers/diff';
import { UserProfileId } from '../../../intl/message.type';
import { User, UserBuilder } from '../../../model/user';
import { useFeature } from '../../../services/feature/use-feature-flag-service';
import { UserForm } from '../../user/user-form';

import '@fontsource/roboto/500.css';

export interface UserManagementProfileFormProps {
  dialogOpen: boolean;
  closeModal: () => void;
}

export const UserManagementProfileForm = (props: UserManagementProfileFormProps) => {

  const { closeModal, dialogOpen } = props;

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const { currentUser: user, fetchCurrentUser } = useCurrentUser();
  const [updatedUser, setUpdatedUser] = useState<User>();
  const { logout } = useAuth0()!;
  const service = useCurrentUserService();
  const rbacRoleManagementFeature = useFeature(Feature.RbacRoleManagement);

  const theme = useTheme();

  type T = UserProfileId;

  const contactAdministratorMessage = useMessage<T>('user-profile.form.contact-administrator');
  const incompleteProfileMessage = useMessage<T>('user-profile.form.incomplete-profile');
  const noRolesPresent = useMessage<T>('user-profile.form.no-roles');

  const cancelMessage = useMessage<T>('user-profile.button.cancel');
  const confirmMessage = useMessage<T>('user-profile.button.confirm');
  const saveMessage = useMessage<T>('user-profile.button.save');
  const cancelModalTitle = useMessage<T>('user-profile.cancel.title');
  const cancelModalMessage = useMessage<T>('user-profile.cancel.message');

  const [updating, setUpdating] = useSafeState(false);
  const [valid, setValid] = useSafeState(false);
  const isFormDisabled = updating;

  useEffect(() => {
    if (hasUserLoaded() && !!user) {
      setUpdatedUser(new UserBuilder().copy(user).build());
    }
  },
  [user]);

  const handleCancelClick = () => {
    closeModal();
    setUpdatedUser({ ...user as User });
  };

  const handleCancelConfirm = () => {
    logout();
  };

  const handleCancelCancel = () => {
    setCancelModalOpen(false);
  };

  const hasUsername = () => !!user && !!user?.username;
  const hasEmail = () => !!user && !!user?.email;
  const hasCompleteProfile = () => !!user && !!user?.hasCompleteProfile;
  const hasUserLoaded = () => !!user;

  const handleSubmit = async () => {
    try {
      setUpdating(true);
      const { userProfile } = user!;
      const { userProfile: nextProfile } = updatedUser!;

      const partial = diff(nextProfile, userProfile);
      // TODO: Need to push the DeepPartial type down, but for now,
      // since user profile is all intrinsic values, Partial is equivalent
      // to DeepPartial in this case.
      await service.patch(partial as Partial<UserProfile>);
      fetchCurrentUser();
      closeModal();
    } finally {
      setUpdating(false);
    }
  };

  const renderButtons = () => {
    return (
      <div className='form-group-buttons' style={{ textAlign: 'end', margin: theme.spacing(2, 0, 0) }}>
        <Button
          variant='outlined'
          onClick={
            rbacRoleManagementFeature && hasUserLoaded() && !hasCompleteProfile()
              ? setCancelModalOpen.bind(null, true)
              : handleCancelClick.bind(null)
          }
          disabled={ isFormDisabled }
          style={{ marginRight: theme.spacing(1) }}
        >
          {cancelMessage}
        </Button>
        <Button
          type='submit'
          variant='contained'
          disabled={isFormDisabled || isEqual(user, updatedUser) || !valid}
          onClick={handleSubmit}
        >
          {saveMessage}
        </Button>
      </div>
    );
  };

  const renderCancelButtons = () => {
    return (
      <div
        className='form-group-cancel-buttons'
        style={{ textAlign: 'end', margin: theme.spacing(2, 0, 0) }}
      >
        <Button
          type='submit'
          color='error'
          variant='outlined'
          onClick={ handleCancelConfirm.bind(null) }
          style={{ marginRight: theme.spacing(1) }}
        >
          {confirmMessage}
        </Button>
        <Button
          id='cancel-cancel-button'
          type='reset'
          variant='outlined'
          onClick={ handleCancelCancel.bind(null) }
        >
          {cancelMessage}
        </Button>
      </div>
    );
  };

  const renderUserEnablements = () => {
    const customerAssignedRole = user && user.enablements?.customerAssignedRole;
    const connectAdminEnabled = user && user.enablements?.connectAdminEnabled;
    const highMemEnabled = user && user.enablements?.highMemEnabled;
    const previewEnabled = user && user.enablements?.previewEnabled;
    const sandboxEnabled = user && user.enablements?.sandboxEnabled;
    const userAdminEnabled = user && user.enablements?.userAdminEnabled;
    const exportAdminEnabled = user && user.enablements?.exportAdminEnabled;

    if (!customerAssignedRole && !connectAdminEnabled && !userAdminEnabled &&
      !highMemEnabled && !previewEnabled && !sandboxEnabled && !exportAdminEnabled) {
      return <div className='no-roles-set-message'>{noRolesPresent}</div>;
    }
    return (
      <ul>
        {!!customerAssignedRole && <li>{customerAssignedRole}</li>}
        {!!connectAdminEnabled  && <li>{AdditionalUserEnablements.APRIORI_CONNECT_ADMIN}</li>}
        {!!userAdminEnabled     && <li>{AdditionalUserEnablements.APRIORI_USER_ADMIN}</li>}
        {!!highMemEnabled       && <li>{AdditionalUserEnablements.APRIORI_HIGH_MEM}</li>}
        {!!previewEnabled       && <li>{AdditionalUserEnablements.APRIORI_PREVIEW}</li>}
        {!!sandboxEnabled       && <li>{AdditionalUserEnablements.APRIORI_SANDBOX}</li>}
        {!!exportAdminEnabled   && <li>{AdditionalUserEnablements.APRIORI_EXPORT_ADMIN}</li>}
      </ul>
    );
  };

  const renderNote = () => {

    if (rbacRoleManagementFeature && !hasCompleteProfile()) {
      return (
        <Alert marginBottom={2} type='warning'>
          {incompleteProfileMessage}
          {renderUserEnablements()}
        </Alert>
      );
    } if (!hasUsername() || !hasEmail()) {
      return (
        <Label
          key='contact-administrator'
          id='contact-administrator'
          className='form-label'
        >
          {contactAdministratorMessage}
        </Label>
      );
    }
    return '';
  };

  const handleUserChange = (user: User) => {
    setUpdatedUser({ ...user });
  };

  const renderForm = () => {

    if (!updatedUser) {
      return;
    }

    return (
      <>
        {renderNote()}
        <UserForm
          user={updatedUser}
          onUserChange={handleUserChange}
          onValidationCheck={setValid}
        />
        {renderButtons()}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>User Profile - {APP_NAME}</title>
      </Helmet>
      <Dialog
        className='edit-user-modal'
        isCancelDisabled={updating}
        isOpen={dialogOpen}
        size='md'
        onClose={
          rbacRoleManagementFeature && hasUserLoaded() && !hasCompleteProfile()
            ? setCancelModalOpen.bind(null, true)
            : closeModal
        }
        title='Edit Profile Details'
      >
        <Dialog
          className='edit-user-cancel-warning-modal'
          isOpen={cancelModalOpen}
          size='sm'
          onClose={setCancelModalOpen.bind(null, false)}
          title={cancelModalTitle}
        >
          {cancelModalMessage}
          {renderCancelButtons()}
        </Dialog>
        {renderForm()}
      </Dialog>
    </>
  );
};
