import { connect } from 'react-redux';

import { GainsightInitializer } from '@aprioritechnologies/core';

const mapStateToProps = (state: any, ownProps: any) => ({
  currentUser: ownProps.currentUser.currentUser,
  ...ownProps
});

export default connect(mapStateToProps, null)(GainsightInitializer);
