import { DEPLOYMENT_SELECTED } from '../constants/actions';
import CloudHomeLocalStorageService from '../services/CloudHomeLocalStorageService';

const initialState = {
  selectedDeploymentIdentity: CloudHomeLocalStorageService.getSelectedDeploymentIdentity() || null
};

// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
  switch (action.type) {

    case DEPLOYMENT_SELECTED: {
      return {
        ...state,
        selectedDeploymentIdentity: action.payload
      };
    }

    default: {
      return state;
    }

  }
}
