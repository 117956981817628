import { CacheService } from './cache.service';

/**
 * A cache service that caches values forever until they are
 * manually busted.
 */
export class CacheForeverService implements CacheService {
  private _cache: Record<string, Promise<any>> = {};

  public has(key: string): boolean {
    return Object.prototype.hasOwnProperty.call(this._cache, key);
  }

  public get<T>(key: string): Promise<T | null> {
    return this._cache[key] || Promise.resolve(null);
  }

  public async compute<T>(key: string, init: () => T | Promise<T>): Promise<T> {
    if (!this.has(key)) {
      await this.put(key, init());
    }

    // We know here that the promise won't return null from the above statement.  It
    // still may if T supports null, which is fine in said case.
    return this.get(key) as Promise<T>;
  }

  public put<T = any>(key: string, value: T | Promise<T>): Promise<T | null> {
    const current = this.get<T>(key);
    this._cache[key] = Promise.resolve(value);
    return current;
  }

  public bust<T = any>(key: string): Promise<T | null> {
    const current = this.get<T>(key);
    delete this._cache[key];
    return current;
  }
}
