import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { selectCustomer } from '../../actions/customer-actions';
import { selectDefaultDeployment, selectDeployment } from '../../actions/deployment-actions';

import AchHeader from './ach-header';

const mapStateToProps = (state: any, ownProps: any) => ({
  currentUser: ownProps.currentUser.currentUser,
  customerList: state.customer.customerList,
  selectedCustomerIdentity: state.customer.selectedCustomerIdentity,
  selectedDeploymentIdentity: state.deployment.selectedDeploymentIdentity
});

const mapDispatchToProps = (dispatch: any) => ({
  selectCustomer: (identity: any) => dispatch(selectCustomer(identity)),
  selectDefaultDeployment: () => dispatch(selectDefaultDeployment()),
  selectDeployment: (identity: any) => dispatch(selectDeployment(identity))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AchHeader));
