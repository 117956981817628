import { connect } from 'react-redux';

import { listCustomers, selectDefaultCustomer } from '../../actions/customer-actions';
import { selectDefaultDeployment } from '../../actions/deployment-actions';

import AchSubHeader from './ach-sub-header';

const mapStateToProps = (state: any) => ({
  selectedCustomerIdentity: state.customer.selectedCustomerIdentity,
  selectedDeploymentIdentity: state.deployment.selectedDeploymentIdentity
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  listCustomers: () => dispatch(listCustomers()),
  selectDefaultCustomer: () => dispatch(selectDefaultCustomer(ownProps.currentUser)),
  selectDefaultDeployment: () => dispatch(selectDefaultDeployment())
});

export default connect(mapStateToProps, mapDispatchToProps)(AchSubHeader);
