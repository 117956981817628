import { snakeCase } from 'lodash';

import { EnvironmentHelpers } from '@aprioritechnologies/core';

import { FeatureFlagService } from './feature-flag-service';

/**
 * Represents a feature flag service that reads the React .env files.
 *
 * This service is a production ready service.  See @see FeatureService for more
 * information about how this behaves.
 *
 * In general, in order to translate from a dot notation feature to a react config,
 * in the .env file, your configurations should start with REACT_APP_FEATURE and all dots,
 * kebabs and spaces should be converted to snake case.
 *
 * @example customers.export-users would translate to REACT_APP_FEATURE_CUSTOMERS_EXPORT_USERS
 */
export class FeatureFlagServiceEnvironment implements FeatureFlagService {
  /**
   * @inheritdoc
   */
  public has(feature: string): Promise<boolean> {
    const categories = feature.split('.')
      .map((category) => snakeCase(category))
      .map((category) => category.toUpperCase());

    let configEntry = 'REACT_APP_FEATURE';

    if (EnvironmentHelpers.getEnvironmentVariable(configEntry, true) === false) {
      // This gives local development an easy way to just turn off all feature
      // flags and just run with everything.
      return Promise.resolve(true);
    }

    for (const category of categories) {
      configEntry = `${configEntry}_${category}`;

      if (EnvironmentHelpers.getEnvironmentVariable(configEntry, true) === false) {
        return Promise.resolve(false);
      }
    }

    return Promise.resolve(true);
  }
}
