import React, { useMemo, useState } from 'react';

import { toLower } from 'lodash';
import { useIntl } from 'react-intl';

import { Option, OptionIdentifier, useMessage } from '@aprioritechnologies/core';
import { FormActionButtons, Select } from '@aprioritechnologies/labs';

import { Deployment } from '../../model/deployment';

import { StyledSwitchDeploymentDialog } from './switch-deployment-dialog.styles';

export interface SwitchDeploymentDialogProps {
  deployments: Deployment[];
  isOpen: boolean;
  value: string;

  onValueChange(value: string): void;
  onCancel(): void;
}

export type SwitchDeploymentDialogId =
  'switch-deployment-dialog.buttons.cancel' |
  'switch-deployment-dialog.buttons.switch' |
  'switch-deployment-dialog.deployment.preview' |
  'switch-deployment-dialog.deployment.production' |
  'switch-deployment-dialog.deployment.sandbox' |
  'switch-deployment-dialog.instructions' |
  'switch-deployment-dialog.title';

export const SwitchDeploymentDialog = (props: SwitchDeploymentDialogProps) => {
  const {
    deployments,
    isOpen,
    value,
    onCancel,
    onValueChange
  } = props;

  const [next, setNext] = useState<string | undefined>(value);
  const { messages } = useIntl();

  type T = SwitchDeploymentDialogId;
  const instructions = useMessage<T>('switch-deployment-dialog.instructions');
  const cancelText = useMessage<T>('switch-deployment-dialog.buttons.cancel');
  const switchText = useMessage<T>('switch-deployment-dialog.buttons.switch');
  const title = useMessage<T>('switch-deployment-dialog.title');

  const options: Option[] = useMemo(() => deployments.map((d) => {
    const type = toLower(d.type);
    const key = `switch-deployment-dialog.deployment.${type}`;
    const displayName = messages[key] as string;
    const identifier = d.identity;
    return { displayName, identifier };
  }), [deployments]);

  const handleCancel = () => {
    onCancel();
    setNext(value);
  };

  const handleSwitch = () => onValueChange(next!);

  const renderFooter = () => {

    return (
      <FormActionButtons
        primaryButtonText={switchText}
        secondaryButtonText={cancelText}
        handlePrimaryButtonClick={handleSwitch}
        handleSecondaryButtonClick={handleCancel}
        isPrimaryButtonDisabled={next == null}
      />
    );
  };

  const renderInstructions = () => (
    <span className='switch-deployment-dialog-instructions'>{instructions}</span>
  );

  const renderDeployments = () => {
    const handleSelect = (v: OptionIdentifier | OptionIdentifier[] | undefined) => {
      const value = v == null ? undefined : `${v}`;
      setNext(value);
    };

    return (
      <Select
        className='switch-deployment-dialog-deployments'
        hideSelectCheck
        options={options}
        value={next}
        onSelect={handleSelect}
      />
    );
  };

  return (
    <StyledSwitchDeploymentDialog
      className='switch-deployment-dialog'
      isOpen={isOpen}
      title={title}
      onClose={handleCancel}
      footer={renderFooter()}
    >
      {renderInstructions()}
      {renderDeployments()}
    </StyledSwitchDeploymentDialog>
  );
};
