import { DependencyList, useEffect } from 'react';

import { usePromiseTimeline, useSafeState } from '@aprioritechnologies/core';

/**
 * A marker for a piece of data that is currently loading.
 */
export const LoadingSymbol = Symbol('loading');

/**
 * This is the type returned from useLoadableState.
 *
 * The first item in the tuple is the current state of the data.  If the
 * data is loading, then this will be @see LoadingSymbol.  If an error occurs,
 * then an object of type @see Error will be returned.  Otherwise, the
 * data returned will be the actual data that was loaded.  You can combine this
 * with the @see DataInitializer component to construct the necessary spinners.
 *
 * The second item in the tuple is a combination of a refresh function and a setter. If
 * this method receieves undefined, then it will do a refresh of the data and
 * load again.  If this method receives a truthy value, then it will immediately
 * set that data without invoking a full load operation.
 */
export type LoadableTuple<TData> = [TData | Error | Symbol, (data?: TData | undefined) => void]

/**
 * Represents a hook that manages data being loaded.
 *
 * @param load
 *        The method that returns a promise to load the desired data.
 *        If the promise returns resolved, then the new data will be set.
 *        If it returns rejected, then the data will be set to an Error instance.
 *        You can combine usages of this hook with the DataInitializer component
 *        to create an error friendly flow.
 * @param deps
 *        The depenendency list to use to auto refresh the data when
 *        one or more of them change.
 *
 * @returns A tuple of the current state of the data as the first item,
 *          and a refresh function as the second item.
 */
export const useLoadableData = <TData>(
  load: () => Promise<TData>,
  deps: DependencyList = []
): LoadableTuple<TData> => {
  const [data, setData] = useSafeState<TData | Error | Symbol>(LoadingSymbol);
  const timeline = usePromiseTimeline<TData>();

  const refresh = (useThisData?: TData | undefined) => {
    if (useThisData !== undefined) {
      setData(useThisData);
      return;
    }

    timeline.record(load())
      .start(() => setData(LoadingSymbol))
      .success((data) => setData(data))
      .failure((e) => setData(new Error(e.toString())))
      .run();
  };

  useEffect(() => { refresh(); }, deps);

  return [data, refresh];
};

/**
 * A type guard that determines if the data is loading.
 *
 * @param data
 *        The data to check.
 *
 * @returns
 *        True if the data is in a loading state.  False if the data has finished
 *        loading, whether that be an error or success.
 */
export const dataIsLoading = <TData>(data: TData | Error | Symbol): data is Symbol => {
  return data === LoadingSymbol;
};

/**
 * A type guard that determines if the data is in an error state.
 *
 * @param data
 *        The data to check.
 *
 * @returns
 *        True if the data has errored.  False if the data has loaded successfully,
 *        or is in the loading state.
 */
export const dataHasErrored = <TData>(data: TData | Error | Symbol): data is Error => {
  return data instanceof Error;
};

/**
 * A type guard that determines if the data has been loaded successfully.
 *
 * @param data
 *        The data to check.
 *
 * @returns
 *        True if data has successfully loaded.  Returns false if the data is loading or
 *        in an error state.
 */
export const dataIsLoaded = <TData>(data: TData | Error | Symbol): data is TData => {
  return !dataIsLoading(data) && !dataHasErrored(data);
};

/**
 * A helper method that can be used to get data or a fallback in the
 * case that the data is not loaded yet.
 *
 * If you have used C# before, this is similar to how it operates with its
 * as keyword.  Typescript has no such functionality to convert objects like
 * this, so we can use this instead.
 *
 * @param data
 *        The data object to return.
 * @param fallback
 *        The fallback to use in the case that the data is still loading or failed.
 *        The default will just return undefined.
 *
 * @returns
 *        This method returns data if it has been loaded successfully, or fallback
 *        in the case that it is in an error state or loading.
 */
export const asData = <TData>(data: TData | Error | Symbol, fallback?: TData): TData | undefined => {
  return dataIsLoaded(data) ? data : fallback;
};
