import { styled } from '@aprioritechnologies/core';

export const StyledUserForm = styled('div')(({ theme }) => {

  return {
    paddingBottom: `${theme.spacing(1)}`,

    '.input-field': {
      input: {
        fontSize: `${theme.spacing(1.5)} !important`
      }
    },

    '.disabled-field': {
      '.apriori-select': {
        color: '#888888 !important',
        'WebkitTextFillColor': theme.palette.grey[500]
      },
      input: {
        color: '#888888 !important',
        'WebkitTextFillColor': theme.palette.grey[500]
      }
    },

    '.viewable-field': {
      '.apriori-select': {
        color: '#000000 !important',
        'WebkitTextFillColor': theme.palette.grey[900]
      },
      input: {
        color: '#888888 !important',
        'WebkitTextFillColor': theme.palette.grey[900]
      }
    },

    '.form-group': {
      marginBottom: '5px',
      marginTop: '5px'
    },

    '.form-buttons': {
      display: 'flex',
      justifyContent: 'flex-end'
    },

    '.form-group-buttons': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(2.5)
    },

    '.no-roles-set-message': {
      fontSize: '1rem',
      textAlign: 'center',
      color: theme.palette.grey[600]
    },

    '.hint-username': {
      marginBottom: 'auto'
    },

    '.hint-text': {
      color: theme.palette.grey[600],
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5)
    }
  };
});
