import { User as CoreUser, CustomerAssignedRole, UserEnablements, UserType } from '@aprioritechnologies/core';
import { ViewModelBuilder } from '@aprioritechnologies/data-source-models';

export interface User extends CoreUser {
  canDelete?: boolean;
  canEditEnablements?: boolean;
  canEditProfile?: boolean;
}

export class UserBuilder {
  public static readonly EnablementsDefault: UserEnablements = Object.freeze({
    customerAssignedRole: undefined,
    userAdminEnabled: false,
    connectAdminEnabled: false,
    highMemEnabled: false,
    previewEnabled: false,
    sandboxEnabled: false,
    exportAdminEnabled: false
  });

  public analyst = this.role.bind(this, CustomerAssignedRole.APRIORI_ANALYST);
  public contributor = this.role.bind(this, CustomerAssignedRole.APRIORI_CONTRIBUTOR);
  public designer = this.role.bind(this, CustomerAssignedRole.APRIORI_DESIGNER);
  public developer = this.role.bind(this, CustomerAssignedRole.APRIORI_DEVELOPER);
  public edc = this.role.bind(this, CustomerAssignedRole.APRIORI_EDC);
  public expert = this.role.bind(this, CustomerAssignedRole.APRIORI_EXPERT);

  private _user: User;

  public constructor(audit = new ViewModelBuilder().build()) {
    this._user = {
      ...audit,
      customerIdentity: '',

      active: true,
      email: '',
      userType: UserType.AP_CLOUD,
      mfaRequired: false,
      username: '',

      userProfile: {
        ...audit,
        familyName: '',
        givenName: ''
      },
      roles: [],
      hasCompleteProfile: false,
      enablements: { ...UserBuilder.EnablementsDefault },
      canDelete: true,
      canEditEnablements: true,
      canEditProfile: true
    };
  }

  public active(active = true): this {
    this._user.active = active;
    return this;
  }

  public customer(customerIdentity: string): this {
    this._user.customerIdentity = customerIdentity;
    return this;
  }

  public username(username: string): this {
    this._user.username = username;
    return this;
  }

  public email(email: string): this {
    this._user.email = email;
    return this;
  }

  public userType(userType: UserType): this {
    this._user.userType = userType;
    return this;
  }

  public name(familyName: string, givenName: string): this {
    this._user.userProfile.familyName = familyName;
    this._user.userProfile.givenName = givenName;
    return this;
  }

  public jobTitle(jobTitle: string): this {
    this._user.userProfile.jobTitle = jobTitle;
    return this;
  }

  public department(department: string): this {
    this._user.userProfile.department = department;
    return this;
  }

  public enablements(enablements: UserEnablements) {
    this._user.enablements = enablements;
    return this;
  }

  public role(customerAssignedRole: CustomerAssignedRole): this {
    return this.updateEnablements({ customerAssignedRole });
  }

  public userAdmin(userAdminEnabled = true): this {
    return this.updateEnablements({ userAdminEnabled });
  }

  public connectAdmin(connectAdminEnabled = true): this {
    return this.updateEnablements({ connectAdminEnabled });
  }

  public exportAdmin(exportAdminEnabled = true): this {
    return this.updateEnablements({ exportAdminEnabled });
  }

  public highMem(highMemEnabled = true): this {
    return this.updateEnablements({ highMemEnabled });
  }

  public preview(previewEnabled = true): this {
    return this.updateEnablements({ previewEnabled });
  }

  public sandbox(sandboxEnabled = true): this {
    return this.updateEnablements({ sandboxEnabled });
  }

  public canDelete(canDelete = true): this {
    this._user.canDelete = canDelete;
    return this;
  }

  public canEditProfile(canEditProfile = true): this {
    this._user.canEditProfile = canEditProfile;
    return this;
  }

  public canEditEnablements(canEditEnablements = true): this {
    this._user.canEditEnablements = canEditEnablements;
    return this;
  }

  public assign(other: Partial<User>): this {
    this._user = { ...this._user, ...other };
    return this;
  }

  public copy(other: User): this {
    this._user = JSON.parse(JSON.stringify(other));
    return this;
  }

  public build(): User {
    return JSON.parse(JSON.stringify(this._user));
  }

  private updateEnablements(enablements: Partial<UserEnablements>) {
    this._user.enablements = {
      ...UserBuilder.EnablementsDefault,
      ...this._user.enablements,
      ...enablements
    };
    return this;
  }
}
