import React, { useEffect, useState } from 'react';

import { concat } from 'lodash';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Divider, GridLayout, Label, Loader, NoContent, Option, OptionIdentifier, Select, SortOperation, Table, Text } from '@aprioritechnologies/core';

import { Notification } from '../../model/notification';
import { NotificationType } from '../../model/notification-type';
import { useNotificationService } from '../../services/notification.service';

import { useNotificationListColumns } from './notification-list-columns';
import { StyledNotificationList } from './notifications-list.styles';

export type NotificationListId =
 'notification-list.column.information' |
 'notification-list.column.type' |
 'notification-list.column.priority' |
 'notification-list.filter.priority.all' |
 'notification-list.filter.priority.urgent' |
 'notification-list.filter.priority.title' |
 'notification-list.filter.sort.newest-first' |
 'notification-list.filter.sort.oldest-first' |
 'notification-list.filter.sort.title' |
 'notification-list.filter.type.all' |
 'notification-list.filter.type.title' |
 'notification-list.no-notifications-available' |
 'notification-list.title';

const NotificationsList = () => {

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [selectedUrgent, setSelectedUrgent] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<SortOperation>(SortOperation.DESC);
  const [selectedType, setSelectedType] = useState<NotificationType | ''>('');
  const [loading, setLoading] = useState<Boolean>(true);

  const columns = useNotificationListColumns();
  const notificationService = useNotificationService();
  const selectedCustomerIdentity = useSelector((state:any) =>
    state.customer.selectedCustomerIdentity
  );
  const selectedDeploymentIdentity = useSelector((state:any) =>
    state.deployment.selectedDeploymentIdentity
  );
  const { messages } = useIntl();

  useEffect(() => {
    async function getToken() {
      setLoading(true);
      if (!!selectedCustomerIdentity && !!selectedDeploymentIdentity) {
        const token = await notificationService.list(
          selectedCustomerIdentity,
          selectedDeploymentIdentity,
          {
            sortBy: selectedSort,
            type: selectedType,
            urgent: selectedUrgent
          }
        );
        setNotifications(token.items);
      }
      setLoading(false);
    }
    getToken();
  },
  [
    selectedCustomerIdentity,
    selectedDeploymentIdentity,
    selectedSort,
    selectedType,
    selectedUrgent
  ]);

  const urgentOptions = [
    {
      displayName: messages['notification-list.filter.priority.all'] as string,
      identifier: 0
    },
    {
      displayName: messages['notification-list.filter.priority.urgent'] as string,
      identifier: 1
    }
  ];
  const handleUrgentSelect = (v: OptionIdentifier | OptionIdentifier[] | undefined) => {
    setSelectedUrgent(!!v);
  };

  const notificationTypes = (Object.keys(NotificationType) as Array<keyof typeof NotificationType>).map(
    notificationType => {
      return {
        displayName: NotificationType[notificationType].toString(),
        identifier: notificationType.toString()
      };
    }
  ) as Option[];
  const allType = [
    {
      displayName: 'All',
      identifier: ''
    }
  ] as Option[];
  const typeOptions = concat(allType, notificationTypes);
  const handleTypeSelect = (v: OptionIdentifier | OptionIdentifier[] | undefined) => {
    setSelectedType(v !== '' ? NotificationType[v as keyof typeof NotificationType] : v);
  };

  const sortOptions = [
    {
      displayName: messages['notification-list.filter.sort.newest-first'] as string,
      identifier: SortOperation.DESC
    },
    {
      displayName: messages['notification-list.filter.sort.oldest-first'] as string,
      identifier: SortOperation.ASC
    }
  ];
  const handleSortSelect = (v: OptionIdentifier | OptionIdentifier[] | undefined) => {
    setSelectedSort(v as SortOperation);
  };

  const renderUrgentDropdown = () => (
    <GridLayout className='priority-dropdown' alignItems='center' gridTemplateColumns='auto 1fr'>
      <Label>{messages['notification-list.filter.priority.title']}:</Label>
      <Select
        disabled={!!loading}
        notNull
        options={urgentOptions}
        value={selectedUrgent ? 1 : 0}
        onSelect={handleUrgentSelect}
      />
    </GridLayout>
  );
  const renderTypeDropdown = () => (
    <GridLayout className='type-dropdown' alignItems='center' gridTemplateColumns='auto 1fr'>
      <Label>{messages['notification-list.filter.type.title']}:</Label>
      <Select
        disabled={!!loading}
        notNull
        options={typeOptions}
        value={selectedType}
        onSelect={handleTypeSelect}
      />
    </GridLayout>
  );
  const renderSortDropdown = () => (
    <GridLayout className='sort-dropdown' alignItems='center' gridTemplateColumns='auto 1fr'>
      <Label>{messages['notification-list.filter.sort.title']}:</Label>
      <Select
        disabled={!!loading}
        notNull
        options={sortOptions}
        value={selectedSort}
        onSelect={handleSortSelect}
      />
    </GridLayout>
  );

  const renderTitle = () => (
    <GridLayout className='notifications-list-title' alignItems='center' gridTemplateColumns='1fr 3fr'>
      <Text.H2 className='notification-name'>{messages['notification-list.title']}</Text.H2>
      <GridLayout className='notification-dropdowns'alignItems='center'gridTemplateColumns='1fr 1fr 1fr'>
        {renderUrgentDropdown()}
        {renderTypeDropdown()}
        {renderSortDropdown()}
      </GridLayout>
    </GridLayout>
  );

  const renderNotifications = () => {
    if (loading) return <Loader />;
    if (!notifications || !notifications.length) return (
      <NoContent message={messages['notification-list.no-notifications-available']} />
    );

    return (
      <Table
        className='notifications-table'
        columns={columns}
        data={notifications}
        paginated={false}
      />
    );
  };

  return (
    <StyledNotificationList className='notifications-list'>
      {renderTitle()}
      <Divider />
      {renderNotifications()}
    </StyledNotificationList>
  );
};

export default NotificationsList;
