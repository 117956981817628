import { RefObject, useEffect, useRef } from 'react';

import { useSafeState } from '@aprioritechnologies/core';

/**
 * A custom React hook that adjusts the height of a `div` element to fill the remaining
 * vertical space from its offset position to the bottom of the window.
 *
 * @returns {[React.RefObject<HTMLDivElement>, number]} - Returns a tuple containing a `ref`
 * to be attached to the `div` element and the calculated height in pixels.
 *
 * @example
 *
 * const ResizableDiv = () => {
 *   const [divRef, height] = useFillHeightFromTop();
 *
 *   return (
 *     <div ref={divRef} style={{ height: `${height}px`, backgroundColor: '#f0f0f0' }}>
 *       This div adjusts its height dynamically.
 *     </div>
 *   );
 * };
 */
const useFullHeightOffset = (): [ RefObject<any>, number ] => {

  const ref = useRef<any>(null);
  const [ height, setHeight ] = useSafeState<number>(0);

  /**
   * Update the height on mount and ref change,
   * create window resize event listener,
   * and cleanup it up on unmount.
   */
  useEffect(() => {

    const updateHeight = () => {
      if (ref.current) {
        setHeight(window.innerHeight - ref.current.offsetTop);
      }
    };

    // Update the height on mount.
    updateHeight();

    // Update height on window resize.
    window.addEventListener('resize', updateHeight);

    // Clean up event listener on component unmount.
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [
    ref.current?.offsetTop
  ]);

  return [ref, height];
};

export default useFullHeightOffset;
