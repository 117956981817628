import { useMemo } from 'react';

import { keyBy, mapValues } from 'lodash';
import { object, string } from 'yup';

import { User, useMessage, useMessageFormatter } from '@aprioritechnologies/core';

import { asData } from '../../hooks/use-loadable-data';
import { UserProfileId } from '../../intl/message.type';
import { useRequiredProperties } from '../../services/user-enablements.service';

export const useUserValidation = (user: User) => {
  type T = UserProfileId;
  const formatMessage = useMessageFormatter<T>();

  const [requiredProperties] = useRequiredProperties(user.enablements?.customerAssignedRole);
  const required = asData(requiredProperties || []);
  const requiredLookup = useMemo(
    () => mapValues(keyBy(required, (r) => r.name), (r) => r.source),
    [required]);
  const requiredMessage = useMessage<T>('user-profile.form.required');
  const invalidEmailMessage = useMessage<T>('user-profile.form.invalid-email');
  const invalidPhoneCountryCodeMessage = useMessage<T>('user-profile.form.invalid-phone-country-code');
  const invalidPhoneNumberMessage = useMessage<T>('user-profile.form.invalid-phone-number');
  const lengthMaxMessage = (value: number) =>formatMessage(
    'user-profile.form.length-max-message',
    { value });

  const phoneCountryCodeRegExp = /^\+?[0-9]{1,3}$/;
  const phoneNumberRegExp = /^([0-9]{3,4}[ \\-]?){2,3}$/;

  const checkRequired = (name: string, baseSchema: any) =>
    Object.prototype.hasOwnProperty.call(requiredLookup, name)
      ? baseSchema.required(requiredMessage)
      : baseSchema;

  const createEmailValidation = () => string()
    .email(invalidEmailMessage)
    .max(128, lengthMaxMessage(128))
    .required(requiredMessage);

  const createUsernameValidation = () => string()
    .max(64, lengthMaxMessage(64))
    .required(requiredMessage);

  const createGivenNameValidation = () => string()
    .max(64, lengthMaxMessage(64))
    .required(requiredMessage);

  const createFamilyNameValidation = () => string()
    .max(254, lengthMaxMessage(254))
    .required(requiredMessage);

  const createPrefixValidation = () => checkRequired('prefix', string()
    .max(8, lengthMaxMessage(8))
    .nullable(true));

  const createSuffixValidation = () => checkRequired('suffix', string()
    .max(8, lengthMaxMessage(8))
    .nullable(true));

  const createJobTitleValidation = () => checkRequired('jobTitle', string()
    .max(64, lengthMaxMessage(64))
    .nullable(true));

  const createDepartmentValidation = () => checkRequired('department', string()
    .max(64, lengthMaxMessage(64))
    .nullable(true));

  const createTownCityValidation = () => checkRequired('townCity', string()
    .max(64, lengthMaxMessage(64))
    .nullable(true));

  const createStateProvinceValidation = () => checkRequired('stateProvince', string()
    .max(2, lengthMaxMessage(2))
    .nullable(true));

  const createCountyValidation = () => checkRequired('county', string()
    .max(64, lengthMaxMessage(64))
    .nullable(true));

  const createCountryCodeValidation = () => checkRequired('countryCode', string()
    .max(3, lengthMaxMessage(3))
    .nullable(true));

  const createTimezoneValidation = () => checkRequired('timezone', string()
    .max(64, lengthMaxMessage(64))
    .nullable(true));

  const createOfficePhoneCountryCodeValidation = () => checkRequired('officePhoneCountryCode', string()
    .matches(phoneCountryCodeRegExp, invalidPhoneCountryCodeMessage)
    .nullable(true));

  const createOfficePhoneNumberValidation = () => checkRequired('officePhoneNumber', string()
    .matches(phoneNumberRegExp, invalidPhoneNumberMessage)
    .nullable(true));

  return useMemo(() => (
    object({
      email: createEmailValidation(),
      username: createUsernameValidation(),
      userProfile: object({
        givenName: createGivenNameValidation(),
        familyName: createFamilyNameValidation(),
        prefix: createPrefixValidation(),
        suffix: createSuffixValidation(),
        jobTitle: createJobTitleValidation(),
        department: createDepartmentValidation(),
        townCity: createTownCityValidation(),
        stateProvince: createStateProvinceValidation(),
        county: createCountyValidation(),
        countryCode: createCountryCodeValidation() ,
        timezone: createTimezoneValidation(),
        officePhoneCountryCode: createOfficePhoneCountryCodeValidation(),
        officePhoneNumber: createOfficePhoneNumberValidation()
      })
    })), [requiredProperties]);
};
