export const CountryData = [
  {
    name: 'Afghanistan',
    alpha2: 'AF',
    alpha3: 'AFG',
    countryCode: '004'
  },
  {
    name: 'Åland Islands',
    alpha2: 'AX',
    alpha3: 'ALA',
    countryCode: '248'
  },
  {
    name: 'Albania',
    alpha2: 'AL',
    alpha3: 'ALB',
    countryCode: '008'
  },
  {
    name: 'Algeria',
    alpha2: 'DZ',
    alpha3: 'DZA',
    countryCode: '012'
  },
  {
    name: 'American Samoa',
    alpha2: 'AS',
    alpha3: 'ASM',
    countryCode: '016'
  },
  {
    name: 'Andorra',
    alpha2: 'AD',
    alpha3: 'AND',
    countryCode: '020'
  },
  {
    name: 'Angola',
    alpha2: 'AO',
    alpha3: 'AGO',
    countryCode: '024'
  },
  {
    name: 'Anguilla',
    alpha2: 'AI',
    alpha3: 'AIA',
    countryCode: '660'
  },
  {
    name: 'Antarctica',
    alpha2: 'AQ',
    alpha3: 'ATA',
    countryCode: '010'
  },
  {
    name: 'Antigua and Barbuda',
    alpha2: 'AG',
    alpha3: 'ATG',
    countryCode: '028'
  },
  {
    name: 'Argentina',
    alpha2: 'AR',
    alpha3: 'ARG',
    countryCode: '032'
  },
  {
    name: 'Armenia',
    alpha2: 'AM',
    alpha3: 'ARM',
    countryCode: '051'
  },
  {
    name: 'Aruba',
    alpha2: 'AW',
    alpha3: 'ABW',
    countryCode: '533'
  },
  {
    name: 'Australia',
    alpha2: 'AU',
    alpha3: 'AUS',
    countryCode: '036'
  },
  {
    name: 'Austria',
    alpha2: 'AT',
    alpha3: 'AUT',
    countryCode: '040'
  },
  {
    name: 'Azerbaijan',
    alpha2: 'AZ',
    alpha3: 'AZE',
    countryCode: '031'
  },
  {
    name: 'Bahamas',
    alpha2: 'BS',
    alpha3: 'BHS',
    countryCode: '044'
  },
  {
    name: 'Bahrain',
    alpha2: 'BH',
    alpha3: 'BHR',
    countryCode: '048'
  },
  {
    name: 'Bangladesh',
    alpha2: 'BD',
    alpha3: 'BGD',
    countryCode: '050'
  },
  {
    name: 'Barbados',
    alpha2: 'BB',
    alpha3: 'BRB',
    countryCode: '052'
  },
  {
    name: 'Belarus',
    alpha2: 'BY',
    alpha3: 'BLR',
    countryCode: '112'
  },
  {
    name: 'Belgium',
    alpha2: 'BE',
    alpha3: 'BEL',
    countryCode: '056'
  },
  {
    name: 'Belize',
    alpha2: 'BZ',
    alpha3: 'BLZ',
    countryCode: '084'
  },
  {
    name: 'Benin',
    alpha2: 'BJ',
    alpha3: 'BEN',
    countryCode: '204'
  },
  {
    name: 'Bermuda',
    alpha2: 'BM',
    alpha3: 'BMU',
    countryCode: '060'
  },
  {
    name: 'Bhutan',
    alpha2: 'BT',
    alpha3: 'BTN',
    countryCode: '064'
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alpha2: 'BO',
    alpha3: 'BOL',
    countryCode: '068'
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'BQ',
    alpha3: 'BES',
    countryCode: '535'
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    alpha3: 'BIH',
    countryCode: '070'
  },
  {
    name: 'Botswana',
    alpha2: 'BW',
    alpha3: 'BWA',
    countryCode: '072'
  },
  {
    name: 'Bouvet Island',
    alpha2: 'BV',
    alpha3: 'BVT',
    countryCode: '074'
  },
  {
    name: 'Brazil',
    alpha2: 'BR',
    alpha3: 'BRA',
    countryCode: '076'
  },
  {
    name: 'British Indian Ocean Territory',
    alpha2: 'IO',
    alpha3: 'IOT',
    countryCode: '086'
  },
  {
    name: 'Brunei Darussalam',
    alpha2: 'BN',
    alpha3: 'BRN',
    countryCode: '096'
  },
  {
    name: 'Bulgaria',
    alpha2: 'BG',
    alpha3: 'BGR',
    countryCode: '100'
  },
  {
    name: 'Burkina Faso',
    alpha2: 'BF',
    alpha3: 'BFA',
    countryCode: '854'
  },
  {
    name: 'Burundi',
    alpha2: 'BI',
    alpha3: 'BDI',
    countryCode: '108'
  },
  {
    name: 'Cabo Verde',
    alpha2: 'CV',
    alpha3: 'CPV',
    countryCode: '132'
  },
  {
    name: 'Cambodia',
    alpha2: 'KH',
    alpha3: 'KHM',
    countryCode: '116'
  },
  {
    name: 'Cameroon',
    alpha2: 'CM',
    alpha3: 'CMR',
    countryCode: '120'
  },
  {
    name: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
    countryCode: '124',
    states: [
      {
        name: 'Alberta',
        stateCode: 'AB'
      },
      {
        name: 'British Columbia',
        stateCode: 'BC'
      },
      {
        name: 'Manitoba',
        stateCode: 'MB'
      },
      {
        name: 'New Brunswick',
        stateCode: 'NB'
      },
      {
        name: 'Newfoundland and Labrador',
        stateCode: 'NL'
      },
      {
        name: 'Northwest Territories',
        stateCode: 'NT'
      },
      {
        name: 'Nova Scotia',
        stateCode: 'NS'
      },
      {
        name: 'Nunavut',
        stateCode: 'NU'
      },
      {
        name: 'Ontario',
        stateCode: 'ON'
      },
      {
        name: 'Prince Edward Island',
        stateCode: 'PE'
      },
      {
        name: 'Quebec',
        stateCode: 'QC'
      },
      {
        name: 'Saskatchewan',
        stateCode: 'SK'
      },
      {
        name: 'Yukon Territory',
        stateCode: 'YT'
      }
    ]
  },
  {
    name: 'Cayman Islands',
    alpha2: 'KY',
    alpha3: 'CYM',
    countryCode: '136'
  },
  {
    name: 'Central African Republic',
    alpha2: 'CF',
    alpha3: 'CAF',
    countryCode: '140'
  },
  {
    name: 'Chad',
    alpha2: 'TD',
    alpha3: 'TCD',
    countryCode: '148'
  },
  {
    name: 'Chile',
    alpha2: 'CL',
    alpha3: 'CHL',
    countryCode: '152'
  },
  {
    name: 'China',
    alpha2: 'CN',
    alpha3: 'CHN',
    countryCode: '156'
  },
  {
    name: 'Christmas Island',
    alpha2: 'CX',
    alpha3: 'CXR',
    countryCode: '162'
  },
  {
    name: 'Cocos (Keeling) Islands',
    alpha2: 'CC',
    alpha3: 'CCK',
    countryCode: '166'
  },
  {
    name: 'Colombia',
    alpha2: 'CO',
    alpha3: 'COL',
    countryCode: '170'
  },
  {
    name: 'Comoros',
    alpha2: 'KM',
    alpha3: 'COM',
    countryCode: '174'
  },
  {
    name: 'Congo',
    alpha2: 'CG',
    alpha3: 'COG',
    countryCode: '178'
  },
  {
    name: 'Congo, Democratic Republic of the',
    alpha2: 'CD',
    alpha3: 'COD',
    countryCode: '180'
  },
  {
    name: 'Cook Islands',
    alpha2: 'CK',
    alpha3: 'COK',
    countryCode: '184'
  },
  {
    name: 'Costa Rica',
    alpha2: 'CR',
    alpha3: 'CRI',
    countryCode: '188'
  },
  {
    name: 'Côte d\'Ivoire',
    alpha2: 'CI',
    alpha3: 'CIV',
    countryCode: '384'
  },
  {
    name: 'Croatia',
    alpha2: 'HR',
    alpha3: 'HRV',
    countryCode: '191'
  },
  {
    name: 'Cuba',
    alpha2: 'CU',
    alpha3: 'CUB',
    countryCode: '192'
  },
  {
    name: 'Curaçao',
    alpha2: 'CW',
    alpha3: 'CUW',
    countryCode: '531'
  },
  {
    name: 'Cyprus',
    alpha2: 'CY',
    alpha3: 'CYP',
    countryCode: '196'
  },
  {
    name: 'Czechia',
    alpha2: 'CZ',
    alpha3: 'CZE',
    countryCode: '203'
  },
  {
    name: 'Denmark',
    alpha2: 'DK',
    alpha3: 'DNK',
    countryCode: '208'
  },
  {
    name: 'Djibouti',
    alpha2: 'DJ',
    alpha3: 'DJI',
    countryCode: '262'
  },
  {
    name: 'Dominica',
    alpha2: 'DM',
    alpha3: 'DMA',
    countryCode: '212'
  },
  {
    name: 'Dominican Republic',
    alpha2: 'DO',
    alpha3: 'DOM',
    countryCode: '214'
  },
  {
    name: 'Ecuador',
    alpha2: 'EC',
    alpha3: 'ECU',
    countryCode: '218'
  },
  {
    name: 'Egypt',
    alpha2: 'EG',
    alpha3: 'EGY',
    countryCode: '818'
  },
  {
    name: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
    countryCode: '222'
  },
  {
    name: 'Equatorial Guinea',
    alpha2: 'GQ',
    alpha3: 'GNQ',
    countryCode: '226'
  },
  {
    name: 'Eritrea',
    alpha2: 'ER',
    alpha3: 'ERI',
    countryCode: '232'
  },
  {
    name: 'Estonia',
    alpha2: 'EE',
    alpha3: 'EST',
    countryCode: '233'
  },
  {
    name: 'Eswatini',
    alpha2: 'SZ',
    alpha3: 'SWZ',
    countryCode: '748'
  },
  {
    name: 'Ethiopia',
    alpha2: 'ET',
    alpha3: 'ETH',
    countryCode: '231'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    alpha2: 'FK',
    alpha3: 'FLK',
    countryCode: '238'
  },
  {
    name: 'Faroe Islands',
    alpha2: 'FO',
    alpha3: 'FRO',
    countryCode: '234'
  },
  {
    name: 'Fiji',
    alpha2: 'FJ',
    alpha3: 'FJI',
    countryCode: '242'
  },
  {
    name: 'Finland',
    alpha2: 'FI',
    alpha3: 'FIN',
    countryCode: '246'
  },
  {
    name: 'France',
    alpha2: 'FR',
    alpha3: 'FRA',
    countryCode: '250'
  },
  {
    name: 'French Guiana',
    alpha2: 'GF',
    alpha3: 'GUF',
    countryCode: '254'
  },
  {
    name: 'French Polynesia',
    alpha2: 'PF',
    alpha3: 'PYF',
    countryCode: '258'
  },
  {
    name: 'French Southern Territories',
    alpha2: 'TF',
    alpha3: 'ATF',
    countryCode: '260'
  },
  {
    name: 'Gabon',
    alpha2: 'GA',
    alpha3: 'GAB',
    countryCode: '266'
  },
  {
    name: 'Gambia',
    alpha2: 'GM',
    alpha3: 'GMB',
    countryCode: '270'
  },
  {
    name: 'Georgia',
    alpha2: 'GE',
    alpha3: 'GEO',
    countryCode: '268'
  },
  {
    name: 'Germany',
    alpha2: 'DE',
    alpha3: 'DEU',
    countryCode: '276'
  },
  {
    name: 'Ghana',
    alpha2: 'GH',
    alpha3: 'GHA',
    countryCode: '288'
  },
  {
    name: 'Gibraltar',
    alpha2: 'GI',
    alpha3: 'GIB',
    countryCode: '292'
  },
  {
    name: 'Greece',
    alpha2: 'GR',
    alpha3: 'GRC',
    countryCode: '300'
  },
  {
    name: 'Greenland',
    alpha2: 'GL',
    alpha3: 'GRL',
    countryCode: '304'
  },
  {
    name: 'Grenada',
    alpha2: 'GD',
    alpha3: 'GRD',
    countryCode: '308'
  },
  {
    name: 'Guadeloupe',
    alpha2: 'GP',
    alpha3: 'GLP',
    countryCode: '312'
  },
  {
    name: 'Guam',
    alpha2: 'GU',
    alpha3: 'GUM',
    countryCode: '316'
  },
  {
    name: 'Guatemala',
    alpha2: 'GT',
    alpha3: 'GTM',
    countryCode: '320'
  },
  {
    name: 'Guernsey',
    alpha2: 'GG',
    alpha3: 'GGY',
    countryCode: '831'
  },
  {
    name: 'Guinea',
    alpha2: 'GN',
    alpha3: 'GIN',
    countryCode: '324'
  },
  {
    name: 'Guinea-Bissau',
    alpha2: 'GW',
    alpha3: 'GNB',
    countryCode: '624'
  },
  {
    name: 'Guyana',
    alpha2: 'GY',
    alpha3: 'GUY',
    countryCode: '328'
  },
  {
    name: 'Haiti',
    alpha2: 'HT',
    alpha3: 'HTI',
    countryCode: '332'
  },
  {
    name: 'Heard Island and McDonald Islands',
    alpha2: 'HM',
    alpha3: 'HMD',
    countryCode: '334'
  },
  {
    name: 'Holy See',
    alpha2: 'VA',
    alpha3: 'VAT',
    countryCode: '336'
  },
  {
    name: 'Honduras',
    alpha2: 'HN',
    alpha3: 'HND',
    countryCode: '340'
  },
  {
    name: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    countryCode: '344'
  },
  {
    name: 'Hungary',
    alpha2: 'HU',
    alpha3: 'HUN',
    countryCode: '348'
  },
  {
    name: 'Iceland',
    alpha2: 'IS',
    alpha3: 'ISL',
    countryCode: '352'
  },
  {
    name: 'India',
    alpha2: 'IN',
    alpha3: 'IND',
    countryCode: '356'
  },
  {
    name: 'Indonesia',
    alpha2: 'ID',
    alpha3: 'IDN',
    countryCode: '360'
  },
  {
    name: 'Iran (Islamic Republic of)',
    alpha2: 'IR',
    alpha3: 'IRN',
    countryCode: '364'
  },
  {
    name: 'Iraq',
    alpha2: 'IQ',
    alpha3: 'IRQ',
    countryCode: '368'
  },
  {
    name: 'Ireland',
    alpha2: 'IE',
    alpha3: 'IRL',
    countryCode: '372'
  },
  {
    name: 'Isle of Man',
    alpha2: 'IM',
    alpha3: 'IMN',
    countryCode: '833'
  },
  {
    name: 'Israel',
    alpha2: 'IL',
    alpha3: 'ISR',
    countryCode: '376'
  },
  {
    name: 'Italy',
    alpha2: 'IT',
    alpha3: 'ITA',
    countryCode: '380'
  },
  {
    name: 'Jamaica',
    alpha2: 'JM',
    alpha3: 'JAM',
    countryCode: '388'
  },
  {
    name: 'Japan',
    alpha2: 'JP',
    alpha3: 'JPN',
    countryCode: '392'
  },
  {
    name: 'Jersey',
    alpha2: 'JE',
    alpha3: 'JEY',
    countryCode: '832'
  },
  {
    name: 'Jordan',
    alpha2: 'JO',
    alpha3: 'JOR',
    countryCode: '400'
  },
  {
    name: 'Kazakhstan',
    alpha2: 'KZ',
    alpha3: 'KAZ',
    countryCode: '398'
  },
  {
    name: 'Kenya',
    alpha2: 'KE',
    alpha3: 'KEN',
    countryCode: '404'
  },
  {
    name: 'Kiribati',
    alpha2: 'KI',
    alpha3: 'KIR',
    countryCode: '296'
  },
  {
    name: 'Korea (Democratic People\'s Republic of)',
    alpha2: 'KP',
    alpha3: 'PRK',
    countryCode: '408'
  },
  {
    name: 'Korea, Republic of',
    alpha2: 'KR',
    alpha3: 'KOR',
    countryCode: '410'
  },
  {
    name: 'Kuwait',
    alpha2: 'KW',
    alpha3: 'KWT',
    countryCode: '414'
  },
  {
    name: 'Kyrgyzstan',
    alpha2: 'KG',
    alpha3: 'KGZ',
    countryCode: '417'
  },
  {
    name: 'Lao People\'s Democratic Republic',
    alpha2: 'LA',
    alpha3: 'LAO',
    countryCode: '418'
  },
  {
    name: 'Latvia',
    alpha2: 'LV',
    alpha3: 'LVA',
    countryCode: '428'
  },
  {
    name: 'Lebanon',
    alpha2: 'LB',
    alpha3: 'LBN',
    countryCode: '422'
  },
  {
    name: 'Lesotho',
    alpha2: 'LS',
    alpha3: 'LSO',
    countryCode: '426'
  },
  {
    name: 'Liberia',
    alpha2: 'LR',
    alpha3: 'LBR',
    countryCode: '430'
  },
  {
    name: 'Libya',
    alpha2: 'LY',
    alpha3: 'LBY',
    countryCode: '434'
  },
  {
    name: 'Liechtenstein',
    alpha2: 'LI',
    alpha3: 'LIE',
    countryCode: '438'
  },
  {
    name: 'Lithuania',
    alpha2: 'LT',
    alpha3: 'LTU',
    countryCode: '440'
  },
  {
    name: 'Luxembourg',
    alpha2: 'LU',
    alpha3: 'LUX',
    countryCode: '442'
  },
  {
    name: 'Macao',
    alpha2: 'MO',
    alpha3: 'MAC',
    countryCode: '446'
  },
  {
    name: 'Madagascar',
    alpha2: 'MG',
    alpha3: 'MDG',
    countryCode: '450'
  },
  {
    name: 'Malawi',
    alpha2: 'MW',
    alpha3: 'MWI',
    countryCode: '454'
  },
  {
    name: 'Malaysia',
    alpha2: 'MY',
    alpha3: 'MYS',
    countryCode: '458'
  },
  {
    name: 'Maldives',
    alpha2: 'MV',
    alpha3: 'MDV',
    countryCode: '462'
  },
  {
    name: 'Mali',
    alpha2: 'ML',
    alpha3: 'MLI',
    countryCode: '466'
  },
  {
    name: 'Malta',
    alpha2: 'MT',
    alpha3: 'MLT',
    countryCode: '470'
  },
  {
    name: 'Marshall Islands',
    alpha2: 'MH',
    alpha3: 'MHL',
    countryCode: '584'
  },
  {
    name: 'Martinique',
    alpha2: 'MQ',
    alpha3: 'MTQ',
    countryCode: '474'
  },
  {
    name: 'Mauritania',
    alpha2: 'MR',
    alpha3: 'MRT',
    countryCode: '478'
  },
  {
    name: 'Mauritius',
    alpha2: 'MU',
    alpha3: 'MUS',
    countryCode: '480'
  },
  {
    name: 'Mayotte',
    alpha2: 'YT',
    alpha3: 'MYT',
    countryCode: '175'
  },
  {
    name: 'Mexico',
    alpha2: 'MX',
    alpha3: 'MEX',
    countryCode: '484'
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha2: 'FM',
    alpha3: 'FSM',
    countryCode: '583'
  },
  {
    name: 'Moldova, Republic of',
    alpha2: 'MD',
    alpha3: 'MDA',
    countryCode: '498'
  },
  {
    name: 'Monaco',
    alpha2: 'MC',
    alpha3: 'MCO',
    countryCode: '492'
  },
  {
    name: 'Mongolia',
    alpha2: 'MN',
    alpha3: 'MNG',
    countryCode: '496'
  },
  {
    name: 'Montenegro',
    alpha2: 'ME',
    alpha3: 'MNE',
    countryCode: '499'
  },
  {
    name: 'Montserrat',
    alpha2: 'MS',
    alpha3: 'MSR',
    countryCode: '500'
  },
  {
    name: 'Morocco',
    alpha2: 'MA',
    alpha3: 'MAR',
    countryCode: '504'
  },
  {
    name: 'Mozambique',
    alpha2: 'MZ',
    alpha3: 'MOZ',
    countryCode: '508'
  },
  {
    name: 'Myanmar',
    alpha2: 'MM',
    alpha3: 'MMR',
    countryCode: '104'
  },
  {
    name: 'Namibia',
    alpha2: 'NA',
    alpha3: 'NAM',
    countryCode: '516'
  },
  {
    name: 'Nauru',
    alpha2: 'NR',
    alpha3: 'NRU',
    countryCode: '520'
  },
  {
    name: 'Nepal',
    alpha2: 'NP',
    alpha3: 'NPL',
    countryCode: '524'
  },
  {
    name: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
    countryCode: '528'
  },
  {
    name: 'New Caledonia',
    alpha2: 'NC',
    alpha3: 'NCL',
    countryCode: '540'
  },
  {
    name: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
    countryCode: '554'
  },
  {
    name: 'Nicaragua',
    alpha2: 'NI',
    alpha3: 'NIC',
    countryCode: '558'
  },
  {
    name: 'Niger',
    alpha2: 'NE',
    alpha3: 'NER',
    countryCode: '562'
  },
  {
    name: 'Nigeria',
    alpha2: 'NG',
    alpha3: 'NGA',
    countryCode: '566'
  },
  {
    name: 'Niue',
    alpha2: 'NU',
    alpha3: 'NIU',
    countryCode: '570'
  },
  {
    name: 'Norfolk Island',
    alpha2: 'NF',
    alpha3: 'NFK',
    countryCode: '574'
  },
  {
    name: 'North Macedonia',
    alpha2: 'MK',
    alpha3: 'MKD',
    countryCode: '807'
  },
  {
    name: 'Northern Mariana Islands',
    alpha2: 'MP',
    alpha3: 'MNP',
    countryCode: '580'
  },
  {
    name: 'Norway',
    alpha2: 'NO',
    alpha3: 'NOR',
    countryCode: '578'
  },
  {
    name: 'Oman',
    alpha2: 'OM',
    alpha3: 'OMN',
    countryCode: '512'
  },
  {
    name: 'Pakistan',
    alpha2: 'PK',
    alpha3: 'PAK',
    countryCode: '586'
  },
  {
    name: 'Palau',
    alpha2: 'PW',
    alpha3: 'PLW',
    countryCode: '585'
  },
  {
    name: 'Palestine, State of',
    alpha2: 'PS',
    alpha3: 'PSE',
    countryCode: '275'
  },
  {
    name: 'Panama',
    alpha2: 'PA',
    alpha3: 'PAN',
    countryCode: '591'
  },
  {
    name: 'Papua New Guinea',
    alpha2: 'PG',
    alpha3: 'PNG',
    countryCode: '598'
  },
  {
    name: 'Paraguay',
    alpha2: 'PY',
    alpha3: 'PRY',
    countryCode: '600'
  },
  {
    name: 'Peru',
    alpha2: 'PE',
    alpha3: 'PER',
    countryCode: '604'
  },
  {
    name: 'Philippines',
    alpha2: 'PH',
    alpha3: 'PHL',
    countryCode: '608'
  },
  {
    name: 'Pitcairn',
    alpha2: 'PN',
    alpha3: 'PCN',
    countryCode: '612'
  },
  {
    name: 'Poland',
    alpha2: 'PL',
    alpha3: 'POL',
    countryCode: '616'
  },
  {
    name: 'Portugal',
    alpha2: 'PT',
    alpha3: 'PRT',
    countryCode: '620'
  },
  {
    name: 'Puerto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
    countryCode: '630'
  },
  {
    name: 'Qatar',
    alpha2: 'QA',
    alpha3: 'QAT',
    countryCode: '634'
  },
  {
    name: 'Réunion',
    alpha2: 'RE',
    alpha3: 'REU',
    countryCode: '638'
  },
  {
    name: 'Romania',
    alpha2: 'RO',
    alpha3: 'ROU',
    countryCode: '642'
  },
  {
    name: 'Russian Federation',
    alpha2: 'RU',
    alpha3: 'RUS',
    countryCode: '643'
  },
  {
    name: 'Rwanda',
    alpha2: 'RW',
    alpha3: 'RWA',
    countryCode: '646'
  },
  {
    name: 'Saint Barthélemy',
    alpha2: 'BL',
    alpha3: 'BLM',
    countryCode: '652'
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2: 'SH',
    alpha3: 'SHN',
    countryCode: '654'
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha2: 'KN',
    alpha3: 'KNA',
    countryCode: '659'
  },
  {
    name: 'Saint Lucia',
    alpha2: 'LC',
    alpha3: 'LCA',
    countryCode: '662'
  },
  {
    name: 'Saint Martin (French part)',
    alpha2: 'MF',
    alpha3: 'MAF',
    countryCode: '663'
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
    alpha3: 'SPM',
    countryCode: '666'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha2: 'VC',
    alpha3: 'VCT',
    countryCode: '670'
  },
  {
    name: 'Samoa',
    alpha2: 'WS',
    alpha3: 'WSM',
    countryCode: '882'
  },
  {
    name: 'San Marino',
    alpha2: 'SM',
    alpha3: 'SMR',
    countryCode: '674'
  },
  {
    name: 'Sao Tome and Principe',
    alpha2: 'ST',
    alpha3: 'STP',
    countryCode: '678'
  },
  {
    name: 'Saudi Arabia',
    alpha2: 'SA',
    alpha3: 'SAU',
    countryCode: '682'
  },
  {
    name: 'Senegal',
    alpha2: 'SN',
    alpha3: 'SEN',
    countryCode: '686'
  },
  {
    name: 'Serbia',
    alpha2: 'RS',
    alpha3: 'SRB',
    countryCode: '688'
  },
  {
    name: 'Seychelles',
    alpha2: 'SC',
    alpha3: 'SYC',
    countryCode: '690'
  },
  {
    name: 'Sierra Leone',
    alpha2: 'SL',
    alpha3: 'SLE',
    countryCode: '694'
  },
  {
    name: 'Singapore',
    alpha2: 'SG',
    alpha3: 'SGP',
    countryCode: '702'
  },
  {
    name: 'Sint Maarten (Dutch part)',
    alpha2: 'SX',
    alpha3: 'SXM',
    countryCode: '534'
  },
  {
    name: 'Slovakia',
    alpha2: 'SK',
    alpha3: 'SVK',
    countryCode: '703'
  },
  {
    name: 'Slovenia',
    alpha2: 'SI',
    alpha3: 'SVN',
    countryCode: '705'
  },
  {
    name: 'Solomon Islands',
    alpha2: 'SB',
    alpha3: 'SLB',
    countryCode: '090'
  },
  {
    name: 'Somalia',
    alpha2: 'SO',
    alpha3: 'SOM',
    countryCode: '706'
  },
  {
    name: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
    countryCode: '710'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS',
    alpha3: 'SGS',
    countryCode: '239'
  },
  {
    name: 'South Sudan',
    alpha2: 'SS',
    alpha3: 'SSD',
    countryCode: '728'
  },
  {
    name: 'Spain',
    alpha2: 'ES',
    alpha3: 'ESP',
    countryCode: '724'
  },
  {
    name: 'Sri Lanka',
    alpha2: 'LK',
    alpha3: 'LKA',
    countryCode: '144'
  },
  {
    name: 'Sudan',
    alpha2: 'SD',
    alpha3: 'SDN',
    countryCode: '729'
  },
  {
    name: 'Suriname',
    alpha2: 'SR',
    alpha3: 'SUR',
    countryCode: '740'
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha2: 'SJ',
    alpha3: 'SJM',
    countryCode: '744'
  },
  {
    name: 'Sweden',
    alpha2: 'SE',
    alpha3: 'SWE',
    countryCode: '752'
  },
  {
    name: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
    countryCode: '756'
  },
  {
    name: 'Syrian Arab Republic',
    alpha2: 'SY',
    alpha3: 'SYR',
    countryCode: '760'
  },
  {
    name: 'Taiwan, Province of China',
    alpha2: 'TW',
    alpha3: 'TWN',
    countryCode: '158'
  },
  {
    name: 'Tajikistan',
    alpha2: 'TJ',
    alpha3: 'TJK',
    countryCode: '762'
  },
  {
    name: 'Tanzania, United Republic of',
    alpha2: 'TZ',
    alpha3: 'TZA',
    countryCode: '834'
  },
  {
    name: 'Thailand',
    alpha2: 'TH',
    alpha3: 'THA',
    countryCode: '764'
  },
  {
    name: 'Timor-Leste',
    alpha2: 'TL',
    alpha3: 'TLS',
    countryCode: '626'
  },
  {
    name: 'Togo',
    alpha2: 'TG',
    alpha3: 'TGO',
    countryCode: '768'
  },
  {
    name: 'Tokelau',
    alpha2: 'TK',
    alpha3: 'TKL',
    countryCode: '772'
  },
  {
    name: 'Tonga',
    alpha2: 'TO',
    alpha3: 'TON',
    countryCode: '776'
  },
  {
    name: 'Trinidad and Tobago',
    alpha2: 'TT',
    alpha3: 'TTO',
    countryCode: '780'
  },
  {
    name: 'Tunisia',
    alpha2: 'TN',
    alpha3: 'TUN',
    countryCode: '788'
  },
  {
    name: 'Turkey',
    alpha2: 'TR',
    alpha3: 'TUR',
    countryCode: '792'
  },
  {
    name: 'Turkmenistan',
    alpha2: 'TM',
    alpha3: 'TKM',
    countryCode: '795'
  },
  {
    name: 'Turks and Caicos Islands',
    alpha2: 'TC',
    alpha3: 'TCA',
    countryCode: '796'
  },
  {
    name: 'Tuvalu',
    alpha2: 'TV',
    alpha3: 'TUV',
    countryCode: '798'
  },
  {
    name: 'Uganda',
    alpha2: 'UG',
    alpha3: 'UGA',
    countryCode: '800'
  },
  {
    name: 'Ukraine',
    alpha2: 'UA',
    alpha3: 'UKR',
    countryCode: '804'
  },
  {
    name: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
    countryCode: '784'
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2: 'GB',
    alpha3: 'GBR',
    countryCode: '826'
  },
  {
    name: 'United States of America',
    alpha2: 'US',
    alpha3: 'USA',
    countryCode: '840',
    states: [
      {
        name: 'Alabama',
        stateCode: 'AL'
      },
      {
        name: 'Alaska',
        stateCode: 'AK'
      },
      {
        name: 'American Samoa',
        stateCode: 'AS'
      },
      {
        name: 'Arizona',
        stateCode: 'AZ'
      },
      {
        name: 'Arkansas',
        stateCode: 'AR'
      },
      {
        name: 'California',
        stateCode: 'CA'
      },
      {
        name: 'Colorado',
        stateCode: 'CO'
      },
      {
        name: 'Connecticut',
        stateCode: 'CT'
      },
      {
        name: 'Delaware',
        stateCode: 'DE'
      },
      {
        name: 'District Of Columbia',
        stateCode: 'DC'
      },
      {
        name: 'Federated States Of Micronesia',
        stateCode: 'FM'
      },
      {
        name: 'Florida',
        stateCode: 'FL'
      },
      {
        name: 'Georgia',
        stateCode: 'GA'
      },
      {
        name: 'Guam',
        stateCode: 'GU'
      },
      {
        name: 'Hawaii',
        stateCode: 'HI'
      },
      {
        name: 'Idaho',
        stateCode: 'ID'
      },
      {
        name: 'Illinois',
        stateCode: 'IL'
      },
      {
        name: 'Indiana',
        stateCode: 'IN'
      },
      {
        name: 'Iowa',
        stateCode: 'IA'
      },
      {
        name: 'Kansas',
        stateCode: 'KS'
      },
      {
        name: 'Kentucky',
        stateCode: 'KY'
      },
      {
        name: 'Louisiana',
        stateCode: 'LA'
      },
      {
        name: 'Maine',
        stateCode: 'ME'
      },
      {
        name: 'Marshall Islands',
        stateCode: 'MH'
      },
      {
        name: 'Maryland',
        stateCode: 'MD'
      },
      {
        name: 'Massachusetts',
        stateCode: 'MA'
      },
      {
        name: 'Michigan',
        stateCode: 'MI'
      },
      {
        name: 'Minnesota',
        stateCode: 'MN'
      },
      {
        name: 'Mississippi',
        stateCode: 'MS'
      },
      {
        name: 'Missouri',
        stateCode: 'MO'
      },
      {
        name: 'Montana',
        stateCode: 'MT'
      },
      {
        name: 'Nebraska',
        stateCode: 'NE'
      },
      {
        name: 'Nevada',
        stateCode: 'NV'
      },
      {
        name: 'New Hampshire',
        stateCode: 'NH'
      },
      {
        name: 'New Jersey',
        stateCode: 'NJ'
      },
      {
        name: 'New Mexico',
        stateCode: 'NM'
      },
      {
        name: 'New York',
        stateCode: 'NY'
      },
      {
        name: 'North Carolina',
        stateCode: 'NC'
      },
      {
        name: 'North Dakota',
        stateCode: 'ND'
      },
      {
        name: 'Northern Mariana Islands',
        stateCode: 'MP'
      },
      {
        name: 'Ohio',
        stateCode: 'OH'
      },
      {
        name: 'Oklahoma',
        stateCode: 'OK'
      },
      {
        name: 'Oregon',
        stateCode: 'OR'
      },
      {
        name: 'Palau',
        stateCode: 'PW'
      },
      {
        name: 'Pennsylvania',
        stateCode: 'PA'
      },
      {
        name: 'Puerto Rico',
        stateCode: 'PR'
      },
      {
        name: 'Rhode Island',
        stateCode: 'RI'
      },
      {
        name: 'South Carolina',
        stateCode: 'SC'
      },
      {
        name: 'South Dakota',
        stateCode: 'SD'
      },
      {
        name: 'Tennessee',
        stateCode: 'TN'
      },
      {
        name: 'Texas',
        stateCode: 'TX'
      },
      {
        name: 'Utah',
        stateCode: 'UT'
      },
      {
        name: 'Vermont',
        stateCode: 'VT'
      },
      {
        name: 'Virgin Islands',
        stateCode: 'VI'
      },
      {
        name: 'Virginia',
        stateCode: 'VA'
      },
      {
        name: 'Washington',
        stateCode: 'WA'
      },
      {
        name: 'West Virginia',
        stateCode: 'WV'
      },
      {
        name: 'Wisconsin',
        stateCode: 'WI'
      },
      {
        name: 'Wyoming',
        stateCode: 'WY'
      }
    ]
  },
  {
    name: 'United States Minor Outlying Islands',
    alpha2: 'UM',
    alpha3: 'UMI',
    countryCode: '581'
  },
  {
    name: 'Uruguay',
    alpha2: 'UY',
    alpha3: 'URY',
    countryCode: '858'
  },
  {
    name: 'Uzbekistan',
    alpha2: 'UZ',
    alpha3: 'UZB',
    countryCode: '860'
  },
  {
    name: 'Vanuatu',
    alpha2: 'VU',
    alpha3: 'VUT',
    countryCode: '548'
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2: 'VE',
    alpha3: 'VEN',
    countryCode: '862'
  },
  {
    name: 'Viet Nam',
    alpha2: 'VN',
    alpha3: 'VNM',
    countryCode: '704'
  },
  {
    name: 'Virgin Islands (British)',
    alpha2: 'VG',
    alpha3: 'VGB',
    countryCode: '092'
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha2: 'VI',
    alpha3: 'VIR',
    countryCode: '850'
  },
  {
    name: 'Wallis and Futuna',
    alpha2: 'WF',
    alpha3: 'WLF',
    countryCode: '876'
  },
  {
    name: 'Western Sahara',
    alpha2: 'EH',
    alpha3: 'ESH',
    countryCode: '732'
  },
  {
    name: 'Yemen',
    alpha2: 'YE',
    alpha3: 'YEM',
    countryCode: '887'
  },
  {
    name: 'Zambia',
    alpha2: 'ZM',
    alpha3: 'ZMB',
    countryCode: '894'
  },
  {
    name: 'Zimbabwe',
    alpha2: 'ZW',
    alpha3: 'ZWE',
    countryCode: '716'
  }
];
