// Customers.
export const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED';
export const LIST_CUSTOMERS_FAILED = 'LIST_CUSTOMERS_FAILED';
export const LIST_CUSTOMERS_PENDING = 'LIST_CUSTOMERS_PENDING';
export const LIST_CUSTOMERS_SUCCEEDED = 'LIST_CUSTOMERS_SUCCEEDED';

// Deployments.
export const DEPLOYMENT_SELECTED = 'DEPLOYMENT_SELECTED';

// Notifications.
export const LIST_NOTIFICATIONS_FAILED = 'LIST_NOTIFICATIONS_FAILED';
export const LIST_NOTIFICATIONS_PENDING = 'LIST_NOTIFICATIONS_PENDING';
export const LIST_NOTIFICATIONS_SUCCEEDED = 'LIST_NOTIFICATIONS_SUCCEEDED';

// Resources.
export const LIST_RESOURCES_PENDING = 'LIST_RESOURCES_PENDING';
export const LIST_RESOURCES_SUCCEEDED = 'LIST_RESOURCES_SUCCEEDED';
