import React from 'react';

import { Form, Formik, FormikProps } from 'formik';
import { camelCase, get, noop, set } from 'lodash';

import {
  GridLayout,
  InputField,
  Option,
  OptionIdentifier,
  SelectField,
  useMessage
} from '@aprioritechnologies/core';

import { CountryData } from '../../constants/country-data';
import { Timezones } from '../../constants/timezones';
import { dataIsLoaded, dataIsLoading } from '../../hooks/use-loadable-data';
import { UserProfileId } from '../../intl/message.type';
import { User } from '../../model/user';
import { useRequiredProperties } from '../../services/user-enablements.service';

import { useUserValidation } from './use-user-validation';
import { StyledUserForm } from './user-form.styles';

interface Field {
  label: string,
  id: string,
  name: string,
  disabled: boolean,
  required?: boolean,
  options?: Option<any>[],
  hintText?: string;
}

interface UserFormProps {
  user: User;
  isFormDisabled?: boolean;
  onUserChange: (user: User) => void;
  onValidationCheck?: (state: boolean) => void;
}

export const UserForm = (props: UserFormProps) => {
  const {
    user,
    isFormDisabled=false,
    onUserChange,
    onValidationCheck=noop
  } = props;

  const [requiredProperties] = useRequiredProperties(user.enablements?.customerAssignedRole);
  const loading = dataIsLoading(requiredProperties);
  const validationSchema = useUserValidation(user);

  type T = UserProfileId;
  const selectHintMessage = useMessage<T>('user-profile.form.select-value');
  const emailHintText = useMessage<T>('user-profile.form.email-hint-text');

  const usernameLabel = useMessage<T>('user-profile.form.username');
  const emailLabel = useMessage<T>('user-profile.form.email');
  const givenNameLabel = useMessage<T>('user-profile.form.givenName');
  const familyNameLabel = useMessage<T>('user-profile.form.familyName');
  const prefixLabel = useMessage<T>('user-profile.form.prefix');
  const suffixLabel = useMessage<T>('user-profile.form.suffix');
  const jobTitleLabel = useMessage<T>('user-profile.form.job-title');
  const departmentLabel = useMessage<T>('user-profile.form.department');
  const townCityLabel = useMessage<T>('user-profile.form.townCity');
  const countyLabel = useMessage<T>('user-profile.form.county');
  const countryLabel = useMessage<T>('user-profile.form.country');
  const timezoneLabel = useMessage<T>('user-profile.form.timezone');
  const officePhoneCountryCodeLabel = useMessage<T>('user-profile.form.office-phone-country-code');
  const officePhoneNumberLabel = useMessage<T>('user-profile.form.office-phone-number');

  const hasEmail = () => !!user && !!user?.identity && !!user?.email;
  const isEmailDisabled = () => hasEmail() || isFormDisabled;

  const countryOptions: Option[] = Object.values(CountryData).map((country) => ({
    identifier: country.countryCode,
    displayName: country.name,
    data: country.countryCode
  }));

  const timezoneOptions: Option[] = Object.values(Timezones).map((timezone) => ({
    identifier: timezone,
    displayName: timezone.split('_').join(' '),
    data: timezone
  }));

  const formMembers: Field[] = [
    {
      label: emailLabel,
      id: 'email',
      name: 'email',
      disabled: isEmailDisabled(),
      required: true,
      hintText: isEmailDisabled() ? '' : emailHintText
    },
    {
      label: usernameLabel,
      id: 'username',
      name: 'username',
      disabled: true,
      required: false
    },
    {
      label: givenNameLabel,
      id: 'userProfile.givenName',
      name: 'userProfile.givenName',
      disabled: isFormDisabled,
      required: true
    },
    {
      label: familyNameLabel,
      id: 'userProfile.familyName',
      name: 'userProfile.familyName',
      disabled: isFormDisabled,
      required: true
    },
    {
      label: prefixLabel,
      id: 'userProfile.prefix',
      name: 'userProfile.prefix',
      disabled: isFormDisabled
    },
    {
      label: suffixLabel,
      id: 'userProfile.suffix',
      name: 'userProfile.suffix',
      disabled: isFormDisabled
    },
    {
      label: jobTitleLabel,
      id: 'userProfile.jobTitle',
      name: 'userProfile.jobTitle',
      disabled: isFormDisabled
    },
    {
      label: departmentLabel,
      id: 'userProfile.department',
      name: 'userProfile.department',
      disabled: isFormDisabled
    },
    {
      label: townCityLabel,
      id: 'userProfile.townCity',
      name: 'userProfile.townCity',
      disabled: isFormDisabled
    },
    {
      label: countyLabel,
      id: 'userProfile.county',
      name: 'userProfile.county',
      disabled: isFormDisabled
    },
    {
      label: countryLabel,
      id: 'userProfile.countryCode',
      name: 'userProfile.countryCode',
      options: countryOptions,
      disabled: isFormDisabled
    },
    {
      label: timezoneLabel,
      id: 'userProfile.timezone',
      name: 'userProfile.timezone',
      options: timezoneOptions,
      disabled: isFormDisabled
    },
    {
      label: officePhoneCountryCodeLabel,
      id: 'userProfile.officePhoneCountryCode',
      name: 'userProfile.officePhoneCountryCode',
      disabled: isFormDisabled
    },
    {
      label: officePhoneNumberLabel,
      id: 'userProfile.officePhoneNumber',
      name: 'userProfile.officePhoneNumber',
      disabled: isFormDisabled
    }
  ];

  const formatVariableRequiredProps = () => {
    if (!dataIsLoaded(requiredProperties)) return;

    requiredProperties.forEach(property => {
      const { name, source } = property;
      const s = camelCase(source);

      const qualifiedName =  s ? `${s}.${name}` : name;
      const index = formMembers.findIndex(x => x.name === qualifiedName);

      formMembers[index] = {
        ...formMembers[index],
        required: true
      };
    });
  };

  const handleChange = async (
    form: FormikProps<User>,
    fieldName: string,
    value: OptionIdentifier | string | undefined
  ) => {
    set(form.values, fieldName, value);
    const valid = await validationSchema.isValid(form.values);
    onUserChange(form.values);
    onValidationCheck(valid);
  };

  const getHintText = (hintText: string) => {

    if (!hintText) {
      return null;
    }

    return (
      <small className='hint-text'>{hintText}</small>
    );
  };

  const renderForm = () => {

    formatVariableRequiredProps();
    return (
      <StyledUserForm>
        <Formik
          initialValues={user}
          validationSchema={validationSchema}
          onSubmit={noop}
        >
          {(form) =>
            (<Form className='user-form'>
              <GridLayout gridTemplateColumns='1fr 1fr'>
                {formMembers.map((field: any) => {

                  if (field.options) {
                    return (
                      <SelectField
                        {...field}
                        size='small'
                        disabled={field.disabled || loading}
                        key={field.label}
                        placeholder={selectHintMessage}
                        onSelectionChanged={
                          (value: OptionIdentifier | undefined, fieldName: string) =>
                            handleChange(form, fieldName, value)
                        }
                        value={get(form, field.id)}
                      />
                    );
                  }

                  return (
                    <div className={`hint-${field.name}`}>
                      <InputField
                        {...field}
                        disabled={field.disabled || loading}
                        key={field.label}
                        type='text'
                        size='small'
                        onInputChanged={
                          (value: string, fieldName: string) => {
                            handleChange(form, fieldName, value);
                            if (fieldName === 'email') {
                              handleChange(form, 'username', value.split('@')[0]);
                            }
                          }
                        }
                        value={get(form, field.id) || ''}
                      />
                      {getHintText(field.hintText)}
                    </div>
                  );
                })}
              </GridLayout>
            </Form>)}
        </Formik>
      </StyledUserForm>
    );
  };

  return renderForm();
};
