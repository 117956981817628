import { EnvironmentHelpers } from '@aprioritechnologies/core';

export const ACH_API_URL = EnvironmentHelpers.getEnvironmentVariable('REACT_APP_SUPPORTING_API_URL');
export const CUS_API_URL = EnvironmentHelpers.getEnvironmentVariable('REACT_APP_CUS_API');

export const CUSTOMERS_URL =
  `${ACH_API_URL}/customers`;

export const CUSTOMER_URL = (customerIdentity: string) =>
  `${CUSTOMERS_URL}/${customerIdentity}`;

export const CUSTOMER_USERS_URL = (customerIdentity: string) =>
  `${CUSTOMER_URL(customerIdentity)}/users`;

export const CUSTOMER_DEPLOYMENTS_URL = (customerIdentity: string) =>
  `${CUSTOMER_URL(customerIdentity)}/deployments`;

export const CUSTOMER_USER_URL = (customerIdentity: string, userIdentity: string) =>
  `${CUSTOMER_USERS_URL(customerIdentity)}/${userIdentity}`;

export const NOTIFICATIONS_URL = (customerIdentity: string, deploymentIdentity: string) =>
  `${ACH_API_URL}/customers/${customerIdentity}/deployments/${deploymentIdentity}/notifications`;

export const REQUIRED_PROPERTIES_URL = (customerIdentity: string, userIdentity: string) =>
  `${ACH_API_URL}/customers/${customerIdentity}/users/${userIdentity}/required-properties`;

export const ENABLEMENTS_SUPPORT_URL =
  `${ACH_API_URL}/enablements-support`;

export const UPDATE_USER_URL =
  `${ACH_API_URL}/user/current`;

export const GET_USER_URL =
  `${ACH_API_URL}/user/current`;

export const CREATE_USER_ACTIVITY_COOKIELESS_SESSION =
  `${ACH_API_URL}/user-activity-events/cookieless-session`;

export const CREATE_USER_ACTIVITY_SSO_EMBED_URL =
  `${ACH_API_URL}/user-activity-events/sso-embed-url`;

export const CUSTOMER_USER_ACTIVITY_STATUS =
  `${ACH_API_URL}/user-activity-events/status`;

export const GENERATE_USER_ACTIVITY_TOKENS =
  `${ACH_API_URL}/user-activity-events/cookieless-session/embed-tokens`;
