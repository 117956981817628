import React, { useState } from 'react';

import { Card, GridLayout, Loader, useMessage } from '@aprioritechnologies/core';

import { ApplicationCardId } from '../../intl/message.type';
import { Application } from '../../model/application';

import { StyledApplicationCard } from './application-card.styles';
import { Links } from './application-list';

type ApplicationCardLinkTypes = 'documentation' | 'training' | 'support';

interface Props {
  application: Application;
  links?: Links;
}

const ApplicationCard = (props: Props) => {
  const { application, links } = props;
  const [ isLoaderVisible, setLoaderVisible ] = useState<boolean>(false);

  type T = ApplicationCardId;
  const applicationCardLinks: Record<ApplicationCardLinkTypes, string> = {
    documentation: useMessage<T>('application-card.link.title.docs'),
    support: useMessage<T>('application-card.link.title.support'),
    training: useMessage<T>('application-card.link.title.training')
  };

  const cardClicked = () => {
    setLoaderVisible(true);
    setTimeout(() => {
      window.open(application.url, '_blank');
      setLoaderVisible(false);
    }, 1000);
  };

  const renderBody = () => (
    <p className='application-description' title={application.description}>
      {application.description}
    </p>
  );

  const renderTitle = () => {
    const { name } = application;

    const [firstWord, secondWord, ...rest] = name.split(' ').filter((s) => !!s.trim());
    const secondLine = rest.join(' ');
    const darkFirstWord = firstWord === 'aP';

    return (
      <div className='application-banner'>
        <h3 className='application-name'>
          <GridLayout gridTemplateColumns='1fr auto' gap='.5rem'>
            <div className={darkFirstWord ? 'dark-word' : 'light-word'}>{firstWord}</div>
            <div className='light-word'>{secondWord}</div>
          </GridLayout>
          {secondLine ? <div className='light-word'>{secondLine}</div> : <br/>}
        </h3>
      </div>
    );
  };

  const renderFooter = () => (
    <div className='application-footer'>
      <div className='application-installation-name'>
        {application.installationName}
      </div>
      {renderLinks()}
    </div>
  );

  const renderLinks = () => {

    if (!links) {
      return null;
    }

    return (
      <div className='application-list-section-links'>
        {renderLink('documentation', application.cloudReference, links?.docs || '')}
        {renderLink('training', application.cloudReference, links?.training || '')}
        {renderLink('support', application.cloudReference, links?.support || '')}
      </div>
    );
  };

  const renderLink = (
    linkType: ApplicationCardLinkTypes,
    cloudReference: string,
    url: string
  ) => {

    const key = `${linkType}-${cloudReference}`;
    const id = `applications-list-${linkType}-${cloudReference}`;
    const title = applicationCardLinks[linkType];

    return (
      <a
        id={id}
        key={key}
        data-link={key}
        data-testid={key}
        className='application-list-section-link'
        target='_blank'
        rel='noreferrer noopener'
        onClick={(e) => e.stopPropagation()}
        href={url}
      >
        {title}
      </a>
    );
  };

  return (
    isLoaderVisible
      ? <Loader background='opaque' message={`Launching ${application.name}...`} isFullScreen />
      : <StyledApplicationCard
        className='application-card'
        data-application={application.name}
        data-version={application.installationVersion}
        data-installation={application.installationName}
        onClick={cardClicked}
      >
        <Card
          color='light'
          content={{
            title: renderTitle(),
            body: renderBody()
          }}
          footer={renderFooter()}
          headerRight={
            application.iconUrl &&
            (
              <div className='application-icon'>
                <img src={application.iconUrl} alt='Application Icon' />
              </div>
            )
          }
        />
      </StyledApplicationCard>
  );
};

export default ApplicationCard;
