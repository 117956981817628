import { styled } from '@aprioritechnologies/core';

export const StyledUserManagementList = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(4),

    '.add-user-button': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[900]
    }
  };
});
