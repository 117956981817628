import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import customer from './customer-reducer';
import deployment from './deployment-reducer';
import notification from './notification-reducer';

export default (history) => combineReducers({

  customer,
  deployment,
  notification,
  router: connectRouter(history)

});
