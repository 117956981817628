import { createContext, useContext, useEffect } from 'react';

import { usePromiseTimeline, useSafeState } from '@aprioritechnologies/core';

import { FeatureFlagService } from './feature-flag-service';
import { FeatureFlagServiceEnvironment } from './feature-flag-service-environment';

export const FeatureFlagServiceContext = createContext<FeatureFlagService>(
  new FeatureFlagServiceEnvironment());

export const useFeatureFlagService = () => useContext(FeatureFlagServiceContext);

export const useFeature = (feature: string): boolean => {
  // Even though features are turned on by default, we will turn them off
  // while the flag is loading.
  const service = useFeatureFlagService();
  const timeline = usePromiseTimeline<boolean>();
  const [value, setValue] = useSafeState(false);

  const refresh = () => timeline
    .record(service.has(feature))
    .start(() => setValue(false))
    .success((val) => setValue(val))
    .failure(() => setValue(true))
    .run();

  useEffect(() => { refresh(); }, []);

  return value;
};
