import React from 'react';

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';

import { Label } from '@aprioritechnologies/core';

export const createNotificationListColumns = () => {
  const { messages } = useIntl();

  return [
    {
      accessor: 'isUrgent',
      Header: messages['notification-list.column.priority'],
      disableSortBy: true,
      width: 80,

      Cell: (c: any) => {
        const { value } = c;
        return (
          <div className='priority' hidden={!value}>
            <FontAwesomeIcon icon={faExclamationCircle} color='red'/>
          </div>
        );
      }
    },
    {
      accessor: 'type',
      Header: messages['notification-list.column.type'],
      disableSortBy: true,
      width: 80,

      Cell: (c: any) => c.value ? c.value : ''
    },
    {
      accessor: 'title',
      Header: messages['notification-list.column.information'],
      disableSortBy: true,

      Cell: (cell: any) => {
        const { row } = cell;
        const { original } = row;
        const { content, title } = original;

        return (
          <div className='information'>
            <Label>{title}</Label>
            <div>{content}</div>
          </div>
        );
      }
    }
  ];
};

export const useNotificationListColumns = () => createNotificationListColumns();
