import { styled } from '@aprioritechnologies/core';

export const StyledHeader = styled('div')(() => {
  return {
    '.logo': {
      width: '18.25rem',
      height: '2.5rem !important'
    }
  };
});
