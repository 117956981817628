import ApplicationMetadataService from './application-metadata.service';
// import UserService from './user.service';

/**
 * Facade for ACH API resource collections.
 */
class AchApi {

  static ApplicationMetadata = ApplicationMetadataService;
}

export default AchApi;
