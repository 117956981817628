import { createContext, useContext } from 'react';

import { CustomerAssignedRole, HttpHelpers, HttpRequestHelpers } from '@aprioritechnologies/core';

import { ENABLEMENTS_SUPPORT_URL } from '../constants/endpoints';
import { useLoadableData } from '../hooks/use-loadable-data';
import { CustomerAssignedRoleView, EnablementsResponse, RequiredUserPropertyModelView } from '../model/customer-assigned-role-view';

import { CacheForeverService } from './cache/cache-forever.service';
import { CacheService } from './cache/cache.service';

export interface UserEnablementsService {
  get(): Promise<EnablementsResponse>;
  getRequiredProperties(role: CustomerAssignedRole): Promise<RequiredUserPropertyModelView[]>
}

export class HttpUserEnablementsService implements UserEnablementsService {

  public constructor(private _http: HttpHelpers, private _cache: CacheService) {}

  public async get() {
    const url = ENABLEMENTS_SUPPORT_URL;
    const headers = HttpRequestHelpers.getDefaultHeaders();

    const { response } = await this._cache.compute(url, () => this._http.get(url, headers));
    return response;
  }

  public async getRequiredProperties(role: CustomerAssignedRole) {
    const enablements = await this.get();
    const selectedRole = enablements.customerAssignedRoles.find(
      (r: CustomerAssignedRoleView) => r.name === role
    );
    if (!selectedRole) return [];
    return selectedRole.requiredUserProperties;
  }
}

export function createDefaultUsersService(): UserEnablementsService {
  return new HttpUserEnablementsService(HttpRequestHelpers, new CacheForeverService());
}

export const UserEnablementsServiceContext = createContext(createDefaultUsersService());

export const useUserEnablementsService = () => useContext(UserEnablementsServiceContext);

export const useEnablements = () => {
  const service = useUserEnablementsService();
  return useLoadableData(() => service.get(), []);
};

export const useRequiredProperties = (role?: CustomerAssignedRole) => {
  const service = useUserEnablementsService();
  return useLoadableData(() => role ? service.getRequiredProperties(role) : Promise.resolve([]), [role]);
};
