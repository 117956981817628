import { styled } from '@aprioritechnologies/core';

export const StyledUserManagementWizard = styled('div')(({ theme }) => {

  return {

    '.form-group-buttons': {
      display: 'flex',
      justifyContent: 'flex-end'
    },

    '.user-management-wizard-profile': {
      minHeight: theme.spacing(60)
    }
  };
});
