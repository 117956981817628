import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Button } from 'reactstrap';

import {
  Avatar,
  Card,
  Divider,
  GridLayout,
  Label,
  Loader,
  Option,
  useMessage,
  useSafeState,
  useTheme
} from '@aprioritechnologies/core';
import { useCurrentUser } from '@aprioritechnologies/user';

import { APP_NAME } from '../../../constants/app-metadata';
import { CountryData } from '../../../constants/country-data';
import { Feature } from '../../../constants/feature';
import { Timezones } from '../../../constants/timezones';
import  { UserProfileId } from '../../../intl/message.type';
import { useFeature } from '../../../services/feature/use-feature-flag-service';

import { UserManagementProfileForm } from './user-management-profile-form';
import { StyledUserManagementView } from './user-management-view.styles';

import '@fontsource/roboto/500.css';

const UserManagementView = () => {

  const { currentUser: user, fetchCurrentUser } = useCurrentUser();
  const rbacRoleManagementFeature = useFeature(Feature.RbacRoleManagement);

  const theme = useTheme();

  type T = UserProfileId;
  const contactAdministratorMessage = useMessage<T>('user-profile.form.contact-administrator');

  const usernameLabel = useMessage<T>('user-profile.form.username');
  const emailLabel = useMessage<T>('user-profile.form.email');
  const givenNameLabel = useMessage<T>('user-profile.form.givenName');
  const familyNameLabel = useMessage<T>('user-profile.form.familyName');
  const prefixLabel = useMessage<T>('user-profile.form.prefix');
  const suffixLabel = useMessage<T>('user-profile.form.suffix');
  const jobTitleLabel = useMessage<T>('user-profile.form.job-title');
  const departmentLabel = useMessage<T>('user-profile.form.department');
  const townCityLabel = useMessage<T>('user-profile.form.townCity');
  const countyLabel = useMessage<T>('user-profile.form.county');
  const countryLabel = useMessage<T>('user-profile.form.country');
  const timezoneLabel = useMessage<T>('user-profile.form.timezone');
  const officePhoneCountryCodeLabel = useMessage<T>('user-profile.form.office-phone-country-code');
  const officePhoneNumberLabel = useMessage<T>('user-profile.form.office-phone-number');
  const editMessage = useMessage<T>('user-profile.button.edit');

  const hasFamilyName = () => !!user && !!user?.userProfile.familyName;
  const hasGivenName = () => !!user && !!user?.userProfile.givenName;
  const hasUsername = () => !!user && !!user?.username;
  const hasEmail = () => !!user && !!user?.email;
  const hasIncompleteProfile = () => !!user && !user.hasCompleteProfile;

  const [editDialogOpen, setEditDialogOpen] = useSafeState(
    rbacRoleManagementFeature && hasIncompleteProfile()
  );

  const _editDialogOpen = editDialogOpen || (rbacRoleManagementFeature && hasIncompleteProfile());

  const countryOptions: Option[] = Object.values(CountryData).map((country) => ({

    identifier: country.countryCode,
    displayName: country.name,
    data: country.countryCode
  }));
  const checkCountryOptions = (obj: any) => obj.identifier === user?.userProfile.countryCode;

  const timezoneOptions: Option[] = Object.values(Timezones).map((timezone) => ({

    identifier: timezone,
    displayName: timezone.split('_').join(' '),
    data: timezone
  }));

  const checkTimezoneOptions = (obj: any) => obj.identifier === user?.userProfile.timezone;

  const getInitials = () => {

    let initials = '';

    if (!!user) {
      if (hasGivenName()) {
        initials += user.userProfile.givenName.charAt(0).toUpperCase();
      }
      if (hasFamilyName()) {
        initials += user.userProfile.familyName.charAt(0).toUpperCase();
      }
    }

    return initials;
  };

  const formMembers = [
    {
      label: usernameLabel,
      value: user?.username
    },
    {
      label: emailLabel,
      value: user?.email
    },
    {
      label: givenNameLabel,
      value: user?.userProfile.givenName
    },
    {
      label: familyNameLabel,
      value: user?.userProfile.familyName
    },
    {
      label: prefixLabel,
      value: user?.userProfile.prefix
    },
    {
      label: suffixLabel,
      value: user?.userProfile.suffix
    },
    {
      label: jobTitleLabel,
      value: user?.userProfile.jobTitle
    },
    {
      label: departmentLabel,
      value: user?.userProfile.department
    },
    {
      label: townCityLabel,
      value: user?.userProfile.townCity
    },
    {
      label: countyLabel,
      value: user?.userProfile.county
    },
    {
      label: countryLabel,
      value: countryOptions.some(checkCountryOptions)
        ? countryOptions.find((obj: any) =>
          obj.identifier === user?.userProfile.countryCode)?.displayName
        : ''
    },
    {
      label: timezoneLabel,
      value: timezoneOptions.some(checkTimezoneOptions)
        ? timezoneOptions.find((obj: any) => obj.identifier === user?.userProfile.timezone)?.displayName
        : ''
    },
    {
      label: officePhoneCountryCodeLabel,
      value: user?.userProfile.officePhoneCountryCode
    },
    {
      label: officePhoneNumberLabel,
      value: user?.userProfile.officePhoneNumber
    }
  ];

  const renderUserTitle = () => {
    if (hasGivenName() || hasFamilyName()) {
      return (
        <>
          <div
            data-testid='section-header'
            className='section-header'
          >
            User
          </div>
          <div
            data-testid='user-title'
            className='user-title'
          >
            <Avatar
              data-testid='user-icon'
              sizeRem={1.5}
              text={getInitials()}
            />
            <div
              data-testid='user-name'
              className='user-name'
            >
              {user?.userProfile.givenName} {user?.userProfile.familyName}
            </div>
          </div>
          <Divider orientation='horizontal' style={{ margin: theme.spacing(2, 0) }} />
        </>
      );
    }
  };

  const renderNote = () => {
    return !hasUsername() || !hasEmail()
      ? (
        <Label
          key='contact-administrator'
          id='contact-administrator'
          className='form-label'
        >
          {contactAdministratorMessage}
        </Label>)
      : ('');
  };

  const renderField = (field: any) => {

    return (
      <div className='user-field' key={`${field.label.replace(/ /g, '_').toLowerCase()}}`}>
        <div
          className={`field-label ${field.label.replace(/ /g, '_').toLowerCase()}}-label`}
          style={{ color: theme.palette.grey[600], margin: theme.spacing(0, 0, .3) }}
        >
          {field.label}
        </div>
        <div
          className={`field-value ${field.label.replace(/ /g, '_').toLowerCase()}-value`}
          style={{ color: theme.palette.grey[700] }}
        >
          {!!field.value ? field.value : '-'}
        </div>
      </div>
    );
  };

  const renderForm = () => {

    if (!user) {
      return;
    }

    return (
      <div>
        <div
          data-testid='section-header'
          className='section-header'
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <div>
            Contact Info
          </div>
          {renderEditButton()}
        </div>
        <div style={{ margin: theme.spacing(0, 2) }} >
          {renderNote()}
          <GridLayout gridTemplateColumns='1fr 1fr'>
            {formMembers.map((field: any) => {
              return renderField(field);
            })}
          </GridLayout>
        </div>
      </div>
    );
  };

  const renderEditButton = () => {
    return (
      <div className='form-buttons'>
        <Button
          variant='text'
          onClick={() => setEditDialogOpen(true) }
          disabled={_editDialogOpen}
        >
          {editMessage}
        </Button>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>User Profile - {APP_NAME}</title>
      </Helmet>
      <UserManagementProfileForm
        dialogOpen={_editDialogOpen}
        closeModal={() => {
          setEditDialogOpen(false);
          fetchCurrentUser();
        }}
      />
      <StyledUserManagementView>
        {
          !user
            ? <Loader message='Loading User' />
            : (
              <div className='user-management-view'>
                <Card
                  className='card-main'
                  showHeader={false}
                  content={{
                    title: '',
                    body: <div
                      data-testid='user-profile-form'
                      className='user-profile-form'
                    >
                      {renderUserTitle()}
                      {renderForm()}
                    </div>
                  }}
                />
              </div>
            )
        }
      </StyledUserManagementView>
    </>
  );
};

export default UserManagementView;
