import React, { Component } from 'react';

import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class SwitchModalActionButtons extends Component {

  renderConfirmationActionButtons() {
    const { onBack, onCancel, onConfirm } = this.props;

    return (
      <div className='action-buttons'>
        <div>
          <Button color='secondary' onClick={onBack} size='sm'>
            <FontAwesomeIcon className='mr-2' icon={faArrowLeft} />
            Back
          </Button>
        </div>
        <div>
          <Button className='mr-2' color='secondary' onClick={onCancel} size='sm'>
            Cancel
          </Button>
          <Button color='primary' onClick={onConfirm} size='sm'>
            Confirm
          </Button>
        </div>
      </div>
    );
  }

  renderSelectionActionButtons() {
    const { isSwitchDisabled, onCancel, onSwitch } = this.props;

    return (
      <>
        <Button
          color='secondary'
          onClick={onCancel}
          size='sm'
        >
          Cancel
        </Button>
        <Button
          color='primary'
          disabled={isSwitchDisabled}
          onClick={onSwitch}
          size='sm'
        >
          Switch
        </Button>
      </>
    );
  }

  render() {
    const { viewMode } = this.props;

    if (viewMode === 'confirmation') {
      return this.renderConfirmationActionButtons();
    }

    return this.renderSelectionActionButtons();
  };
}

SwitchModalActionButtons.propTypes = {
  isSwitchDisabled: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSwitch: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired
};

export default SwitchModalActionButtons;
