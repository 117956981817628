import { createContext, useContext } from 'react';

import { first } from 'lodash';

import { Deployment as CoreDeployment, HttpHelpers, HttpRequestHelpers, PagedResults } from '@aprioritechnologies/core';
import { DataRequest, DataRequestBuilder, SearchCriterionBuilder } from '@aprioritechnologies/data-source-models';

import { CUSTOMERS_URL, CUSTOMER_DEPLOYMENTS_URL } from '../constants/endpoints';
import { useLoadableData } from '../hooks/use-loadable-data';
import { Customer } from '../model/customer';
import { Deployment } from '../model/deployment';

import { CacheForeverService } from './cache/cache-forever.service';
import { CacheService } from './cache/cache.service';

export interface CustomersService {
  list(req?: DataRequest): Promise<PagedResults<Customer>>;
  get(customerIdentity: string): Promise<Customer>;
  getAvailableDeployments(customerIdentity: string): Promise<CoreDeployment[]>;
  getSelectedDeployment(
    customerIdentity: string,
    deploymentIdentity: string
  ): Promise<Deployment | undefined>;
}
export class HttpCustomersService implements CustomersService {

  public constructor(private _http: HttpHelpers, private _cache: CacheService) {}

  public async list(req?: DataRequest): Promise<PagedResults<Customer>> {
    const url = new DataRequestBuilder()
      .copy(req || new DataRequestBuilder().pageSizeMax().build())
      .buildUrl(CUSTOMERS_URL);

    const headers = HttpRequestHelpers.getDefaultHeaders();

    const { response } = await this._http.get(url, headers);
    return response;
  }

  public async get(customerIdentity: string) {
    const customer = await this._findCustomerByIdentity(customerIdentity);

    return customer ||
      Promise.reject(new Error(`Customer with identity ${customerIdentity} not found.`));
  }

  public async getAvailableDeployments(customerIdentity: string) {
    const url = new DataRequestBuilder()
      .pageSizeMax()
      .buildUrl(CUSTOMER_DEPLOYMENTS_URL(customerIdentity));

    const headers = HttpRequestHelpers.getDefaultHeaders();

    const { response } = await this._cache.compute(url, () => this._http.get(url, headers));
    return response.items;
  }

  public async getSelectedDeployment(customerIdentity: string, deploymentIdentity: string) {
    const customer = await this._findCustomerByIdentity(customerIdentity);
    return customer?.deployments.find(deployment => deployment.identity === deploymentIdentity);
  }

  private async _findCustomerByIdentity(customerIdentity: string): Promise<Customer | undefined> {
    const search = new SearchCriterionBuilder().subject('identity').equals(customerIdentity).build();
    const req = new DataRequestBuilder().filter(search).pageSize(1).build();
    const { items } = await this.list(req);
    return first(items);
  }
}

export function createDefaultCustomersService(): CustomersService {
  return new HttpCustomersService(HttpRequestHelpers, new CacheForeverService());
}

export const CustomerServiceContext = createContext(createDefaultCustomersService());

export const useCustomerService = () => useContext(CustomerServiceContext);

export const useAvailableDeployments = (customerIdentity?: string) => {
  const service = useCustomerService();
  return useLoadableData(() => {
    if (!customerIdentity) {
      return Promise.resolve([]);
    }
    return service.getAvailableDeployments(customerIdentity);
  }, [customerIdentity]);
};

export const useCustomer = (customerIdentity?: string) => {
  const service = useCustomerService();
  return useLoadableData(
    () => customerIdentity == null ? Promise.resolve(undefined) : service.get(customerIdentity),
    [customerIdentity]);
};
