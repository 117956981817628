import { createContext, useContext } from 'react';

import { HttpHelpers, HttpRequestHelpers, PagedResults, SearchOperation, SortOperation, UrlBuilder } from '@aprioritechnologies/core';

import { NOTIFICATIONS_URL } from '../constants/endpoints';
import { Notification } from '../model/notification';
import { NotificationType } from '../model/notification-type';

export interface NotificationListOptions
{
   sortBy?: SortOperation,
   type?: NotificationType | '',
   urgent?: boolean
}

export interface NotificationService {
  list(
    customerIdentity: string,
    deploymentIdentity: string,
    options: NotificationListOptions
  ): Promise<PagedResults<Notification>>
}
export class HttpNotificationService implements NotificationService {

  public constructor(private _http: HttpHelpers) {}

  public async list(
    customerIdentity: string,
    deploymentIdentity: string,
    options: NotificationListOptions
  ) {
    const baseUrl = NOTIFICATIONS_URL(customerIdentity, deploymentIdentity);
    const urlBuilder = new UrlBuilder(baseUrl).sortBy({
      key: 'createdAt',
      operation:  options.sortBy || SortOperation.DESC
    }).addPageSize(1000);

    if (options.type) {
      urlBuilder.addSearchCriterion({
        subject: 'type',
        operation: SearchOperation.EQUAL,
        target: options.type.toUpperCase().replace(' ', '_')
      });
    }
    if (options.urgent) {
      urlBuilder.addSearchCriterion({
        subject: 'urgent',
        operation: SearchOperation.EQUAL,
        target: options.urgent
      });
    };

    const url = urlBuilder.build();
    const headers = HttpRequestHelpers.getDefaultHeaders();

    const { response } = await this._http.get(url, headers);
    return response;
  }
}

export function createDefaultNotificationService(): NotificationService {
  return new HttpNotificationService(HttpRequestHelpers);
}

export const NotificationServiceContext = createContext(createDefaultNotificationService());

export const useNotificationService = () => useContext(NotificationServiceContext);
