import { styled } from '@aprioritechnologies/core';

export const StyledUserManagementView = styled('div')(({ theme }) => {

  return {
    width: '100%',
    padding: `${theme.spacing(3)} ${theme.spacing(5)} !important`,

    '.user-management-view': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '.card-main': {
        height: '100%',
        width: '100%',
        borderRadius: `${theme.spacing(2)} !important`,
        flexGrow: 1
      },

      '.section-header': {
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(2),
        fontSize: theme.spacing(2),
        fontWeight: 600
      },

      '.user-field': {
        '.field-label': {
          fontSize: theme.spacing(1.8)
        },

        '.field-value': {
          fontWeight: 600,
          fontSize: theme.spacing(1.8)
        }
      },

      '.user-title': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(4),

        '.user-name': {
          fontWeight: 600,
          fontSize: theme.spacing(2.5),
          marginLeft: theme.spacing(1),
          lineHeight: theme.spacing(2)
        },

        '.avatar': {
          fontSize: `${theme.spacing(1)} !important`,
          paddingTop: '2px'
        },
        '.user-icon': {
          color: 'white',
          display: 'flex',
          fontSize: theme.spacing(3),
          fontWeight: 100,
          justifyContent: 'center',
          marginBottom: theme.spacing(4),
          marginTop: theme.spacing(4)
        }
      }
    }
  };
});
