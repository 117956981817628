import React, { Component } from 'react';

import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Select } from '@aprioritechnologies/core';

import SwitchModalActionButtons from './switch-modal-action-buttons';

class SwitchCustomerModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedCustomerIdentity: props.selectedCustomerIdentity,
      viewMode: 'selection'
    };
  }

  confirm = () => {
    const { onSelectionChange, toggle } = this.props;
    const { selectedCustomerIdentity } = this.state;

    onSelectionChange(selectedCustomerIdentity);
    toggle();
  };

  findCustomer = (identity) => {
    const { customers } = this.props;
    return customers.find(customer => customer.identity === identity);
  };

  // eslint-disable-next-line react/destructuring-assignment
  isSwitchDisabled = () => this.props.selectedCustomerIdentity === this.state.selectedCustomerIdentity;

  renderCustomerDropdown() {
    const { customers } = this.props;
    const { selectedCustomerIdentity } = this.state;

    const options = customers.map((customer) => ({
      displayName: customer.name,
      identifier: customer.identity
    }));

    return (
      <Select
        options={options}
        onSelect={(identity) => this.setState({ selectedCustomerIdentity: identity })}
        value={selectedCustomerIdentity}
      />
    );
  }

  renderConfirmationViewMode() {
    const { selectedCustomerIdentity } = this.state;

    const { name } = this.findCustomer(selectedCustomerIdentity);
    return <div>Are you sure you want to switch to the <b>{name}</b> customer?</div>;
  };

  renderSelectionViewMode() {
    return (
      <>
        <div className='mb-2'>Select the customer you would like to switch to:</div>
        {this.renderCustomerDropdown()}
      </>
    );
  };

  render() {
    const { isOpen, toggle } = this.props;
    const { viewMode } = this.state;

    return (
      <Modal className='switch-customer-modal' isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <FontAwesomeIcon className='mr-2' icon={faUsers} />
          Switch Customer
        </ModalHeader>
        <ModalBody>
          {
            viewMode === 'selection'
              ? this.renderSelectionViewMode()
              : this.renderConfirmationViewMode()
          }
        </ModalBody>
        <ModalFooter>
          <SwitchModalActionButtons
            isSwitchDisabled={this.isSwitchDisabled()}
            onBack={() => this.setState({ viewMode: 'selection' })}
            onCancel={toggle}
            onConfirm={this.confirm}
            onSwitch={() => this.setState({ viewMode: 'confirmation' })}
            viewMode={viewMode}
          />
        </ModalFooter>
      </Modal>
    );
  };
}

/* eslint-disable react/forbid-prop-types */
SwitchCustomerModal.propTypes = {
  customers: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  selectedCustomerIdentity: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired
};

export default SwitchCustomerModal;
